import * as React from 'react';
import { CSVLink } from 'react-csv';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Grid, Box, Chip } from '@mui/material';
import { CompletedQuizze } from 'common/Modal';
import { useContext } from "react";
import { DashboardContext } from '../dashboard-context';

interface csvDataType {
    User: string,
    Antwort1: string,
    Antwort2: string,
    Antwort3: string,
    Antwort4: string,
    Antwort5: string,
    Ergebnis1: string,
    Ergebnis2: string,
    Ergebnis3: string,
}

export default function LastTenQuizzesTable() {

  const { data } = useContext(DashboardContext) || { data: null };

// completedQuizzes
if (data != null){
    var rows: CompletedQuizze[] =  data.completedQuizzes; 
    
    const headers = [
        { label: "User", key: "User" },
        { label: "Antwort 1:", key: "Antwort1" },
        { label: "Antwort 2:", key: "Antwort2" },
        { label: "Antwort 3:", key: "Antwort3" },
        { label: "Antwort 4:", key: "Antwort4" },
        { label: "Antwort 5:", key: "Antwort5" },
        { label: "Ergebnis 1.", key: "Ergebnis1" },
        { label: "Ergebnis 2.", key: "Ergebnis2" },
        { label: "Ergebnis 3.", key: "Ergebnis3" },
    ];

var csvData: csvDataType[] = [];

if (rows && rows.length > 0){
    for (let i = 0; i < rows.length ; i++) {
  var csvDataRow: csvDataType = {
    User: rows[i].tasteType,
    Antwort1: rows[i].answers[0],
    Antwort2: rows[i].answers[1],
    Antwort3: rows[i].answers[2],
    Antwort4: rows[i].answers[3],
    Antwort5: rows[i].answers[4],
    Ergebnis1: rows[i].wineRecommendations[0],
    Ergebnis2: rows[i].wineRecommendations[1],
    Ergebnis3: rows[i].wineRecommendations[2],
  };
  csvData.push(csvDataRow);
}
return (
    <div className='lastTenQuizzes-table'>
        <TableContainer component={Paper} style={{padding: "1rem"}}>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container >
                    <Grid item lg={6} xs={12} className='last-ten-quizes-main-heading'>
                        Die letzten 10 Geschenke-Quizze:
                    </Grid>
                    <Grid item lg={6} xs={12} sx={{textAlign:'right'}}>
                        <CSVLink data={csvData} headers={headers} filename={"tw-quizzes.csv"}>
                            <Button variant="text" sx={{color:'#000', fontWeight: 600}}>
                                Download
                            </Button>
                        </CSVLink>
                    </Grid>
                </Grid>
            </Box>
            <Table sx={{ minWidth: 350}} size='small' aria-label="a dense table">

                <TableHead>

                    <TableRow  sx={{[`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                                fontSize: '16px',
                                color: '#666',
                                fontWeight:600,
                              }}}>
                        <TableCell>User</TableCell>
                        <TableCell>Antwort 1:</TableCell>
                        <TableCell>Antwort 2:</TableCell>
                        <TableCell>Antwort 3:</TableCell>
                        <TableCell>Antwort 4:</TableCell>
                        <TableCell>Antwort 5:</TableCell>
                        <TableCell>Ergebnis 1.</TableCell>
                        <TableCell>Ergebnis 2.</TableCell>
                        <TableCell>Ergebnis 3.</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {csvData.slice(0, 10).map((row) => (
                        <TableRow
                            key={row.User}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none"
                              }}}
                        >
                            <TableCell component="th" scope="row">
                                {row.User}
                            </TableCell>
                            <TableCell>{row.Antwort1 && <Chip label={row.Antwort1} color='primary'/>}</TableCell>
                            <TableCell>{row.Antwort2 && <Chip label={row.Antwort2} color='primary'/>}</TableCell>
                            <TableCell>{row.Antwort3 && <Chip label={row.Antwort3} color='primary'/>}</TableCell>
                            <TableCell>{row.Antwort4 && <Chip label={row.Antwort4} color='primary'/>}</TableCell>
                            <TableCell>{row.Antwort5 && <Chip label={row.Antwort5} color='primary'/>}</TableCell>
                            <TableCell>{row.Ergebnis1}</TableCell>
                            <TableCell>{row.Ergebnis2}</TableCell>
                            <TableCell>{row.Ergebnis3}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>

)
}else{
    return null;
} }else{
  return null;
}

}
