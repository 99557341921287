import { useState, useContext } from "react";
import TopWineDetails from "./top-wine-details";
import Modal from "common/Modal";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslate } from "@refinedev/core";
import { DashboardContext } from "../dashboard-context";

interface TopWinesPropTypes {
  isTop?: boolean;
}

export default function TopWines({ isTop = true }: TopWinesPropTypes) {
  // var { wines } = useContext(DashboardContext) || { wines: null };
  var { allData } = useContext(DashboardContext) || { allData: null };
  var wines = (allData && allData.length > 0) ? allData[0].top_wines : [];

  const [showModal, setShowModal] = useState(false);
  const [selectedWine, setSelectedWine] = useState(false);

  const t = useTranslate();

  if (!wines.length) return null;
  if (isTop) {
    wines = wines.slice(0, 5);
  } else {
    wines = wines.slice(-5);
  }

  if (!isTop && wines.length < 5) return null;

  // Grid item code

  return (
    <>
      <ToastContainer />
      <Modal
        isOpen={showModal}
        handleModal={setShowModal}
        title={""}
        content={<TopWineDetails selectedWine={selectedWine} />}
      />

      <div className="flow-root mt-6">
        {isTop && (
          <p className="text-xl  p-5">
            <span className="flex items-center">
              {t("mostViewedWines")}
              <ArrowUpwardIcon
                className="ml-1 mr-0.5 h-5 w-5 text-green-500"
                aria-hidden="true"
              />
            </span>
          </p>
        )}

        {!isTop && (
          <p className="text-xl p-5 ">
            <span className="flex items-center">
              {t("leastViewedWines")}
              <ArrowDownwardIcon
                className="ml-1 mr-0.5 h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </span>
          </p>
        )}
        <ul role="list" className="-my-5 divider-y divider-gray-200">
          {wines.map((wine: any) => (
            <li key={wine.name}>
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0"></div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: 0,
                        marginRight: ".5rem",
                      }}
                      className={
                        isTop
                          ? " bg-green-100 text-green-800  inline-flex items-baseline px-1.5 py-0.5 rounded-full text-xs font-small md:mt-2 lg:mt-0"
                          : " bg-red-100 text-red-800 inline-flex items-baseline px-1.5 py-0.5 rounded-full text-xs font-small md:mt-2 lg:mt-0"
                      }
                    >
                      {wine.count}
                      {wine.price > 0 &&
                        wine.count > 1 &&
                        " | " + (wine.price * wine.count).toFixed(2) + "€"}
                    </span>
                    <b style={{ fontSize: "14px", margin: 0 }}>{wine.name}</b>
                  </p>
                  <p
                    style={{ fontSize: "13px", margin: 0 }}
                    className="text-sm text-gray-500 truncate"
                  >
                    {"" + wine.grape + ""}
                  </p>
                  <p
                    style={{ fontSize: "13px", margin: 0 }}
                    className="text-sm text-gray-500 truncate"
                  >
                    {wine.country
                      ? wine.country + " | " + wine.category
                      : "Unbekannt" + " | " + wine.category}
                  </p>
                </div>
              </div>
              <div
                style={{
                  height: "0.5px",
                  backgroundColor: "#c5c5c5",
                  marginTop: "15px",
                }}
              ></div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
