import * as echarts from "echarts";
import _ from "lodash";
import { useEffect, useState } from "react";

interface topWineDetailsPropTypes {
  selectedWine?: any;
}
const TopWineDetails = ({ selectedWine }: topWineDetailsPropTypes) => {
  // if (!selectedWine) return null;
  const [wine, setWine] = useState(selectedWine);

  useEffect(() => {
    var chartDom: any = document.getElementById("wine-details");

    let existInstance = echarts.getInstanceByDom(chartDom);
    if (existInstance) {
      if (true) {
        echarts.dispose(existInstance);
      }
    }

    var myChart = echarts.init(chartDom);
    var option;

    option = {
      title: {
        text: "",
        subtext: "",
        left: "center",
      },
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          name: "" + wine.name,
          type: "pie",
          radius: "50%",
          data: [
            { value: _.random(100, 500), name: "Entdecker" },
            { value: _.random(100, 500), name: "Geniesser" },
            { value: _.random(100, 500), name: "Specialist" },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
    myChart.setOption(option);
  });

  return (
    <>
      <div
        style={{
          height: "500px",
          maxWidth: "100%",
          maxHeight: "500px",
          overflow: "scroll",
        }}
        id="top-wine-details"
      ></div>
    </>
  );
};

export default TopWineDetails;
