import EmailList from "./subModules/email-list";
import GeneralStats from "./subModules/general";
import TasteTypes from "./subModules/taste-types";
import TopWines from "./subModules/top-wines";
import QuizDays from "pages/chart/quiz-days";
import { useTranslate } from "@refinedev/core";
import SalesData from "pages/marketComparision/sales-data";
import { Filter } from "common/Modal";
import LastTenQuizzesTable from "./subModules/last-ten-quizzes";
import TopFiveFoods from "./subModules/top-five-food";
import TopFoodPairingWines from "./subModules/top-food-pairing-wines";
import { Grid } from "@mui/material";
import { DashboardProvider } from "./dashboard-context";

interface DashboardModulesPropTypes {
  filter: Filter;
  setFilter: any;
}

export default function DashboardModules({
  filter,
  setFilter,
}: DashboardModulesPropTypes) {
  const t = useTranslate();
  return (
    <DashboardProvider filter={filter} setFilter={setFilter}>
      <main>
        <div className="py-6">
          <div>
            <h1 className="dashboard-main-title">
              {t("dashboard.title", "Dashboard")}
            </h1>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            {filter?.restaurants?.length && (
              <>
                <GeneralStats />
                <TasteTypes />
                <QuizDays filter={filter} />
                <div className="content-center">
                  <div>
                    <Grid container>
                      <Grid item lg={6} xs={12}>
                        <TopWines />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TopWines isTop={false} />
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className="top-5-pairing-food-container">
                  <Grid container spacing={16}>
                    <Grid item lg={6} xs={12}>
                      <TopFiveFoods />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <TopFoodPairingWines />
                    </Grid>
                  </Grid>
                </div>
                <EmailList />
                <LastTenQuizzesTable />
                <SalesData filter={filter} />
              </>
            )}
          </div>
        </div>
      </main>
    </DashboardProvider>
  );
}
