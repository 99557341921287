import { useState, useContext } from "react";

import WinesByTasteType from "./wines-taste-types";
import { Box, Grid, Paper, styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import { Oval } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@mui/material/styles";
import { DashboardContext } from "../dashboard-context";
import { useTranslate } from "@refinedev/core";

const Item: any = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function TasteTypes() {
  const { tasteTypes } = useContext(DashboardContext) || { tasteTypes: null };
  const { allData } = useContext(DashboardContext) || { allData: null };
  const tasteTypesTest = (allData && allData.length > 0 )? allData[0].taste_type : [];
  const { loading } = useContext(DashboardContext) || { loading: null };
  const [showModal, setShowModal]: any = useState(false);
  const [selectedTasteType, setSelectedTasteType] = useState(false);
  const t = useTranslate();

  function showDetails(type: any) {
    setSelectedTasteType(type);
    setShowModal(!showModal);
  }
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  //Close Modal on click.
  const handleClose = () => {
    setShowModal(false);
  };
  // if (tasteTypes != null) {
  //   return (
  //     <>
  //       <ToastContainer />
  //       <Dialog
  //         fullScreen={fullScreen}
  //         fullWidth={true}
  //         maxWidth="md"
  //         open={showModal}
  //         onClose={handleClose}
  //       >
  //         <DialogTitle style={{ textAlign: "center" }}></DialogTitle>
  //         <DialogContent>
  //           <WinesByTasteType selection={selectedTasteType}></WinesByTasteType>
  //         </DialogContent>
  //         <DialogActions>
  //           <Button
  //             style={{
  //               backgroundColor: "rgb(98 25 64)",
  //               color: "#fff",
  //               textAlign: "center",
  //               width: "100%",
  //             }}
  //             onClick={handleClose}
  //           >
  //             {t("return")}
  //           </Button>
  //         </DialogActions>
  //       </Dialog>

  //       <div style={{ marginLeft: "45%", marginBottom: "2%" }}>
  //         {loading && (
  //           <Oval
  //             height={30}
  //             width={30}
  //             color="#621940"
  //             wrapperStyle={{}}
  //             wrapperClass=""
  //             visible={true}
  //             ariaLabel="oval-loading"
  //             secondaryColor="#621940"
  //             strokeWidth={2}
  //             strokeWidthSecondary={2}
  //           />
  //         )}
  //       </div>

  //       <Box
  //         className="mtop20 padding-0"
  //         sx={{
  //           flexGrow: 1,
  //         }}
  //       >
  //         <Grid lg={12} container spacing={2}>
  //           {tasteTypes.map((item: any, index: any) => (
  //             <Grid key={index} item>
  //               <Item
  //                 onClick={() => showDetails(item)}
  //                 style={{
  //                   backgroundColor: "#f3f4f6",
  //                   cursor: "pointer",
  //                   border: "none",
  //                   boxShadow: "none",
  //                   borderRadius: ".5rem",
  //                 }}
  //               >
  //                 <div className="hoverAndPointerBackground">
  //                   <img
  //                     src={process.env.REACT_APP_BASE_URL + item.asset?.url}
  //                     width="100"
  //                     height="140"
  //                     alt=""
  //                     className="h-100 object-cover pointer-events-none group-hover:opacity-75 hoverAndPointer"
  //                     // onClick={() => showDetails(item)}
  //                   />
  //                 </div>
  //               </Item>
  //               <div style={{ textAlign: "left" }}>
  //                 <div style={{ margin: 0 }}>{item.name}</div>
  //                 <div
  //                   style={{ margin: 0, fontSize: "14px" }}
  //                   className="text-gray-500"
  //                 >
  //                   {item.count}
  //                 </div>
  //               </div>
  //             </Grid>
  //           ))}
  //         </Grid>
  //       </Box>
  //     </>
  //   );
  // }
  if (tasteTypesTest != null) {
    return (
      <>
        <ToastContainer />
        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth="md"
          open={showModal}
          onClose={handleClose}
        >
          <DialogTitle style={{ textAlign: "center" }}></DialogTitle>
          <DialogContent>
            <WinesByTasteType selection={selectedTasteType}></WinesByTasteType>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                backgroundColor: "rgb(98 25 64)",
                color: "#fff",
                textAlign: "center",
                width: "100%",
              }}
              onClick={handleClose}
            >
              {t("return")}
            </Button>
          </DialogActions>
        </Dialog>

        <div style={{ marginLeft: "45%", marginBottom: "2%" }}>
          {loading && (
            <Oval
              height={30}
              width={30}
              color="#621940"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#621940"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          )}
        </div>

        <Box
          className="mtop20 padding-0"
          sx={{
            flexGrow: 1,
          }}
        >
          <Grid lg={12} container spacing={2}>
            {tasteTypesTest.map((item: any, index: any) => (
              <Grid key={index} item>
                <Item
                  onClick={() => showDetails(item)}
                  style={{
                    backgroundColor: "#f3f4f6",
                    cursor: "pointer",
                    border: "none",
                    boxShadow: "none",
                    borderRadius: ".5rem",
                  }}
                >
                  <div className="hoverAndPointerBackground">
                    <img
                      src={process.env.REACT_APP_BASE_URL + item.asset?.url}
                      width="100"
                      height="140"
                      alt=""
                      className="h-100 object-cover pointer-events-none group-hover:opacity-75 hoverAndPointer"
                      // onClick={() => showDetails(item)}
                    />
                  </div>
                </Item>
                <div style={{ textAlign: "left" }}>
                  <div style={{ margin: 0 }}>{item.name}</div>
                  <div
                    style={{ margin: 0, fontSize: "14px" }}
                    className="text-gray-500"
                  >
                    {item.count}
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </>
    );
  } else {
    return null;
  }
}
