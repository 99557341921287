import axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";

import { quizeDaysResponseType, Filter } from "common/Modal";
import { getTokenFromLocalCookie } from "common/CommonService";

export async function fetchParticipants(filter: Filter): Promise<any> {
  const AuthStr = getTokenFromLocalCookie();
  const url: string = process.env.REACT_APP_GET_PARTICIPANTS_DATA || "";
  try {
    // const res = await axios.post(
    //   url,
    //   {
    //     filter: filter,
    //   },
    //   {
    //     headers: {
    //       Authorization: "Bearer " + AuthStr,
    //     },
    //   }
    // );
    // return res.data;
    return [];
  } catch (error: any) {
    toast.error(error);
    return [];
  }
}

export async function fetchEmails(filter: Filter): Promise<any> {
  const AuthStr = getTokenFromLocalCookie();
  const url: string = process.env.REACT_APP_GET_EMAIL_LIST || "";
  try {
    // const res = await axios.post(
    //   url,
    //   {
    //     filter: filter,
    //   },
    //   {
    //     headers: {
    //       Authorization: "Bearer " + AuthStr,
    //     },
    //   }
    // );
    // return res.data;
    return [];
  } catch (error: any) {
    console.log(error);
    return [];
  }
}

export async function fetchTasteTypes(filter: Filter): Promise<any> {
  const AuthStr = getTokenFromLocalCookie();
  const url: string | undefined = process.env.REACT_APP_GET_TASTE_TYPES || "";
  try {
    // const res = await axios.post(
    //   url,
    //   {
    //     filter: filter,
    //   },
    //   {
    //     headers: {
    //       Authorization: "Bearer " + AuthStr,
    //     },
    //   }
    // );
    // if (res.data?.length > 0) {
    //   return _.orderBy(res.data, "count", "desc");
    // } else {
    //   return [];
    // }
    return [];
  } catch (error: any) {
    toast.error(error);
    return [];
  }
}

export async function fetchWines(filter: Filter): Promise<any> {
  const AuthStr = getTokenFromLocalCookie();
  const url: string = process.env.REACT_APP_TOP_WINES || "";
  try {
    // const res = await axios.post(
    //   url,
    //   {
    //     filter: filter,
    //   },
    //   {
    //     headers: {
    //       Authorization: "Bearer " + AuthStr,
    //     },
    //   }
    // );
    // return res.data.wines;
    return [];
  } catch (error: any) {
    toast.error(error);
    return [];
  }
}

export async function fetchQuizDaysData(
  filter: Filter
): Promise<quizeDaysResponseType[]> {
  const AuthStr = getTokenFromLocalCookie();
  const url: string = process.env.REACT_APP_GET_QUIZ_DAYS ?? "";
  try {
    // const res = await axios.post<quizeDaysResponseType[]>(
    //   url,
    //   {
    //     filter: filter,
    //   },
    //   {
    //     headers: {
    //       Authorization: "Bearer " + AuthStr,
    //     },
    //   }
    // );
    // return res.data;
    return [];
  } catch (error) {
    throw new Error("Error fetching quiz days data.");
  }
}

export async function fetchAllData(filter: Filter){
  console.log("filter = ",filter);
  const AuthStr = getTokenFromLocalCookie();
  const url: string = process.env.REACT_APP_BASE_URL + "/api/master/record" ?? "";
  try{
    const res = await axios.post<any>(
      url,
      {
        filter: filter,
      },
      {
        headers: {
          Authorization: "Bearer " + AuthStr,
        },
      }
    )
    return res.data;
  }catch (error) {
    console.log("error");
    throw new Error("Error fetching all data.");
  }
}