import { Fragment } from "react";
import moment from "moment";
import { useContext } from "react";
import "moment/locale/de"; // without this line it didn't work
import { DashboardContext } from "../dashboard-context";
moment.locale("de");

function emailAnonymized(email: any) {
  return email.replace(/(.{3}).*(.{5})/, "$1***$2");
}

export default function EmailList() {
  const { emails } = useContext(DashboardContext) || { emails: null };

  function downloadEmailList() {
    const rows = [
      ...[["email", "vorname", "name", "geschmackstyp", "zugestimmt_am"]],
      ...emails
        .filter((e: any) => !!e.optin_okay)
        .map((e: any) => [
          e.mail,
          e.first_name,
          e.name,
          e.taste_type.name,
          e.optin_okay,
        ]),
    ];

    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "emails.csv");
    document.body.appendChild(link);

    link.click();
  }

  if (!emails.length) return null;
  return (
    <>
      <div className="flow-root xs:m-10 mt-10 relative min-h-full  flex flex-col items-center justify-center p-4 rounded-lg shadow-sm m-auto">
        <h2 className={"text-lg text-center mb-10"}>
          E-Mail Eintragungen{" "}
          <span className="inline-flex items-center rounded-md bg-bordeaux px-2.5 py-0.5 text-sm font-medium text-white">
            {emails.length}
          </span>
        </h2>

        <div className="flex justify-end">
          <button
            type="button"
            className="mb-5 ml-3  rounded-md border border-transparent bg-bordeaux py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-bordeaux focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => {
              downloadEmailList();
            }}
          >
            Eintragungen als CSV herunterladen
          </button>
        </div>

        <ul
          style={{ maxHeight: "500px", overflow: "scroll" }}
          role="list"
          className=""
        >
          {emails.map((email: any, eventIdx: any) => (
            <li key={eventIdx}>
              <div className="flex space-x-6">
                <img
                  className="h-6 w-6 rounded-full"
                  src={
                    process.env.NEXT_PUBLIC_HOST + email.taste_type.asset?.url
                  }
                  alt=""
                />
                <div className="flex-1 space-y-1">
                  <div className="flex items-center justify-between">
                    <h3 className="text-sm font-medium">
                      {emailAnonymized(email.mail)}
                    </h3>
                    <p className="text-sm text-gray-500">
                      {/*{moment(email.createdAt).format("DD.MM.YYYY")}*/}
                      {moment(email.createdAt).startOf("day").fromNow()}
                    </p>
                  </div>
                  <p className="text-sm text-gray-500"></p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
