import { useState, useEffect } from "react";
import { EyeIcon, EyeOffIcon, PencilIcon } from "@heroicons/react/solid";
import Button from "@mui/material/Button";
import axios from "axios";
import CreateProductForm from "./CreateProductForm";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslate } from "@refinedev/core";
import { getTokenFromLocalCookie } from "common/CommonService";
import { Table, Tag, Tooltip } from "antd";

export default function ManageWines() {
  const token = getTokenFromLocalCookie();
  const filter = { token: token };
  const [localFilter] = useState({ ...filter });
  const [gtin, setGtin] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchCounter, setSearchCounter] = useState(0);
  const [products, setProducts] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant]: any = useState(null);
  const [boostActive, setBoostActive] = useState(false);
  const [boostLoading, setBoostLoading] = useState(false);
  const baseUrl: string = process.env.REACT_APP_BASE_URL ?? "";
  const dummyData: any = [];
  const t = useTranslate();
  const columns = [
    {
      title: t("productName"),
      dataIndex: "product_name",
      render: (e: any, record: any) => <>{record.name}</>,
    },
    {
      title: t("hidden"),
      dataIndex: "hidden",
      render: (e: any, record: any) => (
        <Tag color={record.hidden === true ? "green" : "volcano"}>
          {String(record.hidden).toUpperCase()}
        </Tag>
      ),
    },
    {
      title: t("price"),
      dataIndex: "price",
      render: (e: any, record: any) => (
        <>
          {record.price && typeof record.price === "number"
            ? record.price.toFixed(2)
            : "-"}
        </>
      ),
    },
    {
      title: t("offer_price"),
      dataIndex: "meta_data",
      render: (e: any, record: any) => {
        let offerPrice = "-";

        try {
          const metaData = JSON.parse(record.metadata);
          if (metaData && metaData.offer_price !== undefined) {
            offerPrice = `${parseInt(metaData.offer_price).toFixed(2)} €`;
          }
        } catch (error) {
          // JSON parsing error, set offerPrice to dash
        }

        return <>{offerPrice}</>;
      },
    },
    {
      title: t("favorite"),
      dataIndex: "favorite",
      render: (e: any, record: any) => (
        <Tag color={record.favorite === true ? "green" : "volcano"}>
          {String(record.favorite).toUpperCase()}
        </Tag>
      ),
    },
    {
      title: t("edit"),
      dataIndex: "actions",
      render: (e: any, record: any) => (
        <div className="text-center">
          <Tooltip title="Edit Product">
            <a
              onClick={(e: any) => {
                editProduct(e, record);
              }}
              href="#"
              className="mr-2"
            >
              {<PencilIcon className="manage-wine-page-wine-pencil-icon" />}{" "}
            </a>
          </Tooltip>
        </div>
      ),
    },
    {
      title: t("show_hide"),
      dataIndex: "eyeactions",
      render: (e: any, record: any) => (
        <div className="text-center">
          <Tooltip title={record.hidden ? "Show Product" : "Hide Product"}>
            <a
              onClick={(e: any) => {
                updateProduct(e, record, !record.hidden);
              }}
              href="#"
              className="mr-2"
            >
              {record.hidden ? (
                <EyeOffIcon className="manage-wine-page-wine-pencil-icon" />
              ) : (
                <EyeIcon className="manage-wine-page-wine-pencil-icon" />
              )}
            </a>
          </Tooltip>
        </div>
      ),
    },
  ];


  const createProduct = () => {
    setShowForm(true);
    setSelectedProduct(null);
  };
  const editProduct = (e: any, product: any) => {
    e.preventDefault();
    setShowForm(true);
    setSelectedProduct(product);
  };

  const updateProduct = (e: any, record: any, hideValue: any) => {
    e.preventDefault();
    const gtinValue = record.drink.gtin;
    const url: string = process.env.REACT_APP_CREATE_PRODUCT ?? "";
    try {
      axios
        .post(
          url,
          {
            GTIN: gtinValue,
            location: selectedRestaurant,
            isNewProduct: false,
            hidden: hideValue,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          toast.success("Product updated successfuly");
          // // searchProduct();
          // fetchAllWines(selectedRestaurant)
          if(gtin){
            searchProduct();
          }else{
            fetchAllWines(selectedRestaurant)
          }

        });
    } catch (error: any) {
      toast.error(error);
    }
  };

  //Fetch Boost State.
  function getBoostState() {
    setBoostLoading(true);
    try {
      axios
        .get(
          baseUrl + "/api/partner/boostmode?restaurantId=" + selectedRestaurant,
          {
            headers: {
              Authorization: `Bearer ${localFilter.token}`,
            },
          }
        )
        .then((res) => {
          setBoostActive(res.data);
          setBoostLoading(false);
        });
    } catch (error: any) {
      setBoostLoading(false);
      toast.error(error);
    }
  }

  //Toggle Boost
  function toggleBoost() {
    setBoostLoading(true);
    try {
      axios
        .post(
          baseUrl + "/api/partner/boostmode?restaurantId=" + selectedRestaurant,
          {
            state: !boostActive,
          },
          {
            headers: {
              Authorization: `Bearer ${localFilter.token}`,
            },
          }
        )
        .then((res: any) => {
          getBoostState();
        });
    } catch (error: any) {
      setBoostLoading(false);
      toast.error(error);
    }
  }

  //Search Product
  function searchProduct() {
    
    console.log("gtingtingtingtingtingtin ",gtin)
    // if(!gtin){
    //   return;
    // }
    if (loading) return;
    setLoading(true);
    if (!gtin) {
      setError("Bitte GTIN eingeben");
      fetchAllWines(selectedRestaurant);
      return;
    } else {
      setError("");
    }
    setProducts([]);
    try {
      axios
        .get(
          baseUrl +
            "/api/partner/product?gtin=" +
            gtin +
            "&token=" +
            localFilter.token +
            "&restaurantId=" +
            selectedRestaurant,
          {
            headers: {
              Authorization: `Bearer ${localFilter.token}`,
            },
          }
        )
        .then((res) => {
          console.log("resresresres = ", res);
          setProducts(res.data);
          setSearchCounter(searchCounter + 1);
          setLoading(false);
        });
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
  }

  //Fetch Restaurant Data.
  function fetchRestaurants() {
    try {
      axios
        .get(baseUrl + "/api/partner/data/restaurants", {
          headers: {
            Authorization: "Bearer " + localFilter.token,
          },
        })
        .then((res) => {
          setRestaurants(res.data);
          // setCategories(res.data[0].categories);
          setSelectedRestaurant(res.data[0].id);
          fetchAllWines(res.data[0].id);
        });
    } catch (error: any) {
      toast.error(error);
    }
  }

  function fetchAllWines(restaurantId: any) {
    setLoading(true)
    try {
      axios
        .get(
          baseUrl +
            "/api/partner/product/restaurant?restaurantId=" +
            restaurantId,
          {
            headers: {
              Authorization: "Bearer " + localFilter.token,
            },
          }
        )
        .then((res) => {
          console.log("---response = ",res);
          setProducts(res.data)
          setLoading(false);
          // setRestaurants(res.data);
          // // setCategories(res.data[0].categories);
          // setSelectedRestaurant(res.data[0].id);
          // fetchAllWines(res.data[0].id);
        });
    } catch (error: any) {
      toast.error(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchRestaurants();
    setProducts(dummyData);
  }, [setProducts]);

  useEffect(() => {
    if (selectedRestaurant) {
      getBoostState();
    }
  }, [selectedRestaurant]);

  if (!restaurants.length) return <div></div>;
  return (
    <>
      <ToastContainer />
      {!showForm && (
        <div className="form-align-center">
          {/* <div className={`file_container ${!selectedFile && 'file_container_center'} `}>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept=".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
            <Tooltip title="Upload excel file">
              <button onClick={handleButtonClick}>
                Upload
              </button>
            </Tooltip>
            {selectedFile && (<div className="filename-container"><p className="selected_file_name">Selected File: {selectedFile.name}</p>
            <button className="close-button" onClick={()  => resetFileInput()}>
                <i className="icofont icofont-close"></i>
            </button>
            </div>)}
            {selectedFile && <button onClick={(e: any) => handleUpload(e)}>Submit</button>}
          </div> */}

          <p className="" id="search-description">
            {t(
              "manageWineTopLine",
              "Hier kannst du Weine anlegen oder einen Weinanhand der GTIN suchen und bearbeiten."
            )}
          </p>
          <div className="search_restaurant_box">
            <Select
              value={selectedRestaurant}
              onChange={(e: any) => {
                setSelectedRestaurant(e.target.value);
                fetchAllWines(e.target.value);
                setError("");
              }}
              autoComplete="location"
              className="manageWine-dropDown"
            >
              {restaurants.map((restaurant: any) => (
                <MenuItem key={restaurant.id} value={restaurant.id}>
                  {restaurant.name}
                </MenuItem>
              ))}
            </Select>
            <div className="manage-wine-btn-container">
              <Button
                sx={{
                  fontSize: 12,
                  marginBottom: 2,
                  backgroundColor: "rgb(98 25 64)",
                }}
                size="small"
                variant="contained"
                onClick={() => {
                  toggleBoost();
                }}
                className=""
                disabled={boostLoading}
              >
                {/* {boostLoading
                  ? "..."
                  : "Boost modus " +
                    (boostActive ? "deaktivieren" : "aktivieren")} */}
                {boostLoading
                  ? "..."
                  : t("activateBoostMode", "activateBoostMode") +
                    (boostActive
                      ? t("deactivate", "deactivate")
                      : t("activate", "activate"))}
              </Button>
            </div>
          </div>
          <div className="gtinTextStyle">
            <TextField
              type="text"
              name="gtin"
              value={gtin}
              onChange={(e: any) => setGtin(e.target.value)}
              className="manageWine-IputField"
              placeholder="8437019544102"
              autoFocus={true}
              onFocus={(e: any) => e.currentTarget.select()}
              id="outlined-basic"
              label="GTIN"
              variant="outlined"
            />
          </div>
          <p style={{ color: "red" }}>{error}</p>
          <div className="">
            <Button
              onClick={() => searchProduct()}
              variant="contained"
              className="manage-wine-page-btn-lukingforwine"
              size="small"
              style={{ backgroundColor: "#621940", marginTop: 10 }}
            >
              {loading ? (
                <div className={""}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 38 38"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="#fff"
                  >
                    <g fill="none" fillRule="evenodd">
                      <g transform="translate(1 1)" strokeWidth="2">
                        <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                        <path d="M36 18c0-9.94-8.06-18-18-18">
                          <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 18 18"
                            to="360 18 18"
                            dur="1s"
                            repeatCount="indefinite"
                          />
                        </path>
                      </g>
                    </g>
                  </svg>
                </div>
              ) : (
                t("lookingForWine", "LOOKING FOR WINE")
              )}
            </Button>
          </div>

          {/* {Array.isArray(products) && products.length > 0 && (
            <div className="manage-wine-page-wine-main-container">
              {products.map((product: any) => (
                <div
                  key={product.id}
                  onClick={() => editProduct(product)}
                  className=""
                >
                  <Box
                  sx={{
                    width: 300,
                    height: 'auto',
                    border: '0.8px solid #ddd',
                    padding:'5px 20px',
                    borderRadius: '10px',
                    '&:hover': {
                      border: '0.8px solid #888',
                    },
                  }}>
                    <a className="clickable">
                      <span className="" aria-hidden="true" />
                      <div className="pencil-div">
                      <PencilIcon  className="manage-wine-page-wine-pencil-icon" />
                      </div>
                      <div style={{textAlign: 'left'}}>
                      <p className="manage-wine-page-wine-text">
                        {product.drink.GTIN} 
                      </p>
                      <p className="manage-wine-page-wine-text-2">{product.name}</p>
                      <p className="manage-wine-page-wine-text-2">{product.category.restaurant.name}</p>
                      </div>
                    </a>
                  </Box>
                </div>
              ))}
            </div>
          )} */}
          {!loading &&
            !error &&
            Array.isArray(products) &&
            products.length === 0 &&
            searchCounter > 0 && (
              <div className="">
                <p className="">{t("noResultFound")}</p>
                <Button
                  variant="contained"
                  onClick={() => {
                    createProduct();
                  }}
                  style={{ backgroundColor: "rgb(98 25 64)" }}
                  className="manage-wine-page-btn-createWine"
                >
                  {t("createWine", "createWine")}
                </Button>
              </div>
            )}

          {Array.isArray(products) && products.length > 0 && (
            <div className="manage-wine-data-table">
              <Table dataSource={products} columns={columns} />
            </div>
          )}
        </div>
      )}

      {showForm && (
        <div className="">
          <CreateProductForm
            showForm={showForm}
            product={selectedProduct}
            location={selectedRestaurant}
            gtin={gtin}
            // reset={reset}
            setShowForm={setShowForm}
            token={localFilter.token}
            // searchProduct={searchProduct}
            // fetchAllWines={fetchAllWines}
            handleShowWines={(e: any)=>{e.preventDefault(); if(gtin){searchProduct();}else{fetchAllWines(selectedRestaurant)}}}
          ></CreateProductForm>
        </div>
      )}
    </>
  );
}
