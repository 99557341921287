import { Menu } from '@mui/icons-material';
import {
  AppBar,
  Avatar,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  useGetIdentity,
  useGetLocale,
  useSetLocale,
} from '@refinedev/core';
import { RefineThemedLayoutHeaderProps } from '@refinedev/mui';
import React from 'react';

import i18n from 'i18n';

type IUser = {
  id: number;
  username: string;
};

export const Header: React.FC<RefineThemedLayoutHeaderProps> = ({
  isSiderOpen,
  onToggleSiderClick,
  toggleSiderIcon: toggleSiderIconFromProps,
}) => {
  const { data: user } = useGetIdentity<IUser>();

  const changeLanguage = useSetLocale();
  const locale = useGetLocale();
  const currentLocale = locale();

  const hasSidebarToggle = Boolean(onToggleSiderClick);

  return (
    <AppBar
      position="static"
      sx={{ margin: 0, backgroundColor: 'white', borderBottom: '1px solid rgb(234 233 226)' }}
    >
      <Toolbar>
        <Stack direction="row" width="100%" alignItems="center">
          {hasSidebarToggle && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => onToggleSiderClick?.()}
              edge="start"
              sx={{
                mr: 0,
                display: { xs: 'none', md: 'flex' },
                ...(isSiderOpen && { display: 'none' }),
              }}
            >
              {toggleSiderIconFromProps?.(Boolean(isSiderOpen)) ?? (
                <Menu />
              )}
            </IconButton>
          )}

          <Stack
            direction="row"
            width="100%"
            justifyContent="flex-end"
            alignItems="center"
            gap="16px"
          >
            <FormControl sx={{ minWidth: 120 }}>
              <Select
                disableUnderline
                defaultValue={currentLocale}
                inputProps={{ 'aria-label': 'Without label' }}
                variant="standard"
                sx={{
                  color: 'inherit',
                  '& .MuiSvgIcon-root': {
                    color: 'inherit',
                  },
                }}
              >
                {[...(i18n.languages ?? [])]
                  .sort()
                  .map((lang: string) => (
                    // @ts-ignore
                    <MenuItem
                      selected={currentLocale === lang}
                      key={lang}
                      defaultValue={lang}
                      onClick={() => {
                        changeLanguage(lang);
                      }}
                      value={lang}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Avatar
                          sx={{
                            width: '16px',
                            height: '16px',
                            marginRight: '3px',
                          }}
                          src={`/images/flags/${lang}.svg`}
                        />
                        <Typography sx={{ color: 'black' }}>
                          {lang === "en" ? "English" : lang === "de" ? "German" : "Spanish"}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {user?.username && (
              <Stack
                direction="row"
                gap="16px"
                alignItems="center"
                justifyContent="center"
              >
                {user?.username && (
                  <Typography
                    variant="subtitle2"
                    sx={{ color: 'black' }}
                  >
                    {user?.username}
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
