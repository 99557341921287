import { useState, useContext } from "react";
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AddsClickIcon from "@mui/icons-material/AdsClick";
import AssistantIcon from "@mui/icons-material/Assistant";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { Oval } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslate } from "@refinedev/core";
import { DashboardContext } from "../dashboard-context";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  position: "relative",
}));

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: ".5rem",
};

export default function GeneralStats() {
  const { data } = useContext(DashboardContext) || { data: null };
  const { allData } = useContext(DashboardContext) || { allData: null };
  const { loading } = useContext(DashboardContext) || { loading: null };
  const t = useTranslate();

  const [openedInfotextId, setOpenedInfotextId] = useState(
    null as number | null
  );

  const helperTexts = [
    {
      title: "CTR",
      description: t(
        "Die CTR (Click-Through-Rate) misst den Prozentsatz der User, die den Chatbot geöffnet haben, einen Ablauf bis zur einer Weinempfehlung durchlaufen haben und schließlich die Empfehlung geöffnet haben."
      ),
    },
    {
      title: "CTR " + t("of recommendations"),
      description: t(
        "Die CTR (Click-Through-Rate) der Empfehlungen misst den Prozentsatz der User, welche eine Weinempfehlung bekommen und geöffnet haben."
      ),
    },
    {
      title: t("Chatbot opened"),
      description: t("Anzahl der User, die den Chatbot geöffnet haben."),
    },
  ];

  //Get stats data to show
  if (allData != null) {
     const quizStats = [
      {
          "name": "Quiz Teilnehmer (- / 5 ⭐️)",
          "stat": 0,
          "previousStat": 0,
          "change": "0.00%",
          "changeType": "decrease"
      },
      {
          "name": "Quiz Absolviert",
          "stat": 0,
          "previousStat": 0,
          "change": "0.00%",
          "changeType": "decrease"
      },
      {
          "name": "Ø Quiz Dauer",
          "stat": "0s",
          "previousStat": "0s",
          "change": "0%",
          "changeType": "increase"
      },
      {
          "name": "Ø Weinpreis",
          "stat": "0€",
          "previousStat": "0€",
          "change": "0.00%",
          "changeType": "decrease"
      }
  ]
    // console.log("=-=-=-=-=-allData = ",allData[0].quiz_stats.stats)
    // var stats: any = data.stats;
    var stats: any = allData.length > 0 ? allData[0].quiz_stats.stats : quizStats;
    // var ctr: any = data.clickThroughRate | 0;
    // var ctr: any = allData.length > 0 && allData[0].click_through_rate.clickThroughRate ? allData[0].click_through_rate.clickThroughRate : 0;
    var ctr: any = 0;
    // var botOpenendCount: any = data.botOpenendCount | 0;
    var botOpenendCount: any = allData.length > 0 && allData[0].bot_oppened_count | 0;
  }

  return (
    <div>
      <ToastContainer />
      <h3
        style={{ margin: "-5px 0 0 0" }}
        className="text-lg leading-6 font-medium text-gray-900"
      >
        {t("overview", "overview")}
      </h3>
      <div
        className="loaderStyle"
        style={{ marginLeft: "45%", marginBottom: "2%" }}
      >
        {loading && (
          <Oval
            height={30}
            width={30}
            color="#621940"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#621940"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        )}
      </div>
      {!loading && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container lg={12} spacing={0.5}>
            {stats &&
              stats.map((item: any, index: any) => (
                <Grid key={index} item lg={3} xs={12}>
                  <Item>
                    <div>
                      <h4
                        style={{
                          textAlign: "start",
                          fontWeight: "normal",
                          color: "#000",
                          marginTop: ".5rem",
                          fontSize: "16px",
                        }}
                      >
                        {item.name}
                      </h4>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "0 0 0 3px",
                        }}
                        className="flex items-baseline text-2xl font-semibold text-bordeaux"
                      >
                        <div>
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "22px",
                            }}
                            className="text-bordeaux"
                          >
                            {item.stat}
                          </span>{" "}
                          {(item.stat !== 0 || item?.previousStat !== 0) && (
                            <span className="ml-2 text-sm font-medium text-gray-500">
                              {t("beforeHand")} {item?.previousStat}
                            </span>
                          )}
                        </div>
                        <Chip
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                          sx={
                            item?.change >= "0%"
                              ? { bgcolor: "#D1F7DF", color: "#000" }
                              : { bgcolor: "#F3D7D7", color: "#000" }
                          }
                          icon={
                            item?.change >= "0%" ? (
                              <ArrowUpwardIcon
                                fontSize="small"
                                color="success"
                              />
                            ) : (
                              <ArrowDownwardIcon
                                fontSize="small"
                                color="error"
                              />
                            )
                          }
                          label={
                            <span
                              style={{ fontWeight: "600" }}
                              className={
                                item?.change >= "0%"
                                  ? "text-green-800"
                                  : "text-red-800"
                              }
                            >
                              {item?.change}
                            </span>
                          }
                        />
                      </div>
                    </div>
                  </Item>
                </Grid>
              ))}
            {ctr?.clickThroughRate ? (
              <Grid item lg={2} xs={6}>
                <Item>
                  <div>
                    <h4
                      style={{
                        textAlign: "start",
                        fontWeight: "normal",
                        color: "#000",
                        marginTop: ".5rem",
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <AddsClickIcon
                        fontSize="small"
                        style={{ marginRight: ".2rem" }}
                      />
                      CTR
                    </h4>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "0 0 0 3px",
                      }}
                      className="flex items-baseline text-2xl font-semibold text-bordeaux"
                    >
                      <div>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "22px",
                          }}
                          className="text-bordeaux"
                        >
                          {ctr.clickThroughRate?.toFixed(2)} %
                        </span>
                      </div>
                    </div>
                  </div>
                  <IconButton
                    aria-label="info"
                    size="small"
                    style={{ position: "absolute", bottom: 0, right: 0 }}
                    onClick={() => setOpenedInfotextId(0)}
                  >
                    <InfoIcon style={{ color: "#82888a", opacity: 0.5 }} />
                  </IconButton>
                </Item>
              </Grid>
            ) : null}
            {ctr?.clickThroughRateRecommendation ? (
              <Grid item lg={3} xs={6}>
                <Item>
                  <div>
                    <h4
                      style={{
                        textAlign: "start",
                        fontWeight: "normal",
                        color: "#000",
                        marginTop: ".5rem",
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <AddsClickIcon
                        fontSize="small"
                        style={{ marginRight: ".2rem" }}
                      />
                      CTR {t("of recommendations")}
                    </h4>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "0 0 0 3px",
                      }}
                      className="flex items-baseline text-2xl font-semibold text-bordeaux"
                    >
                      <div>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "22px",
                          }}
                          className="text-bordeaux"
                        >
                          {ctr.clickThroughRateRecommendation?.toFixed(2)} %
                        </span>
                      </div>
                    </div>
                  </div>
                  <IconButton
                    aria-label="info"
                    size="small"
                    style={{ position: "absolute", bottom: 0, right: 0 }}
                    onClick={() => setOpenedInfotextId(1)}
                  >
                    <InfoIcon style={{ color: "#82888a", opacity: 0.5 }} />
                  </IconButton>
                </Item>
              </Grid>
            ) : null}
            {botOpenendCount >= 0 ? (
              <Grid item lg={2} xs={6}>
                <Item>
                  <div>
                    <h4
                      style={{
                        textAlign: "start",
                        fontWeight: "normal",
                        color: "#000",
                        marginTop: ".5rem",
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <AssistantIcon
                        fontSize="small"
                        style={{ marginRight: ".2rem" }}
                      />
                      {t("Chatbot opened")}
                    </h4>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "0 0 0 3px",
                      }}
                      className="flex items-baseline text-2xl font-semibold text-bordeaux"
                    >
                      <div>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "22px",
                          }}
                          className="text-bordeaux"
                        >
                          {botOpenendCount}
                        </span>
                      </div>
                    </div>
                  </div>
                  <IconButton
                    aria-label="info"
                    size="small"
                    style={{ position: "absolute", bottom: 0, right: 0 }}
                    onClick={() => setOpenedInfotextId(2)}
                  >
                    <InfoIcon style={{ color: "#82888a", opacity: 0.5 }} />
                  </IconButton>
                </Item>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      )}
      <Modal
        open={(openedInfotextId !== null && openedInfotextId >= 0) ?? false}
        onClose={() => setOpenedInfotextId(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {openedInfotextId !== null && helperTexts[openedInfotextId].title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {openedInfotextId !== null &&
              helperTexts[openedInfotextId].description}
          </Typography>
          <IconButton
            aria-label="info"
            size="small"
            style={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => setOpenedInfotextId(null)}
          >
            <CloseIcon style={{ color: "black" }} />
          </IconButton>
        </Box>
      </Modal>
    </div>
  );
}
