import Cookies from "js-cookie";

export const setToken = (data) => {
  if (typeof window === "undefined") {
    return;
  }
  Cookies.set("jwt", data);
};

export const unsetToken = () => {
  if (typeof window === "undefined") {
    return;
  }

  Cookies.remove("jwt");
};

export const getTokenFromLocalCookie = () => {
  return Cookies.get("jwt");
};

export const mockFilter = {
      "startDate": "2023-05-26T05:45:16.490Z",
      "endDate": "2023-05-26T05:45:16.490Z",
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNjgwNjczNTE2LCJleHAiOjE2ODMyNjU1MTZ9.EpQr4OqmgKirQEdwrpWp1TZ3tuwXt6nnu8Rz8WOoRl8",
      "restaurants": [
          {
              "id": 12,
              "ign_id": null,
              "ign_code": "E-WARNOWPARK",
              "name": "E-Center Warnowpark",
              "description": null,
              "createdAt": "2022-09-01T13:19:03.855Z",
              "updatedAt": "2022-10-04T12:20:34.005Z",
              "street": null,
              "zipcode": null,
              "city": null,
              "position": 0,
              "hidden": false,
              "enabled": true,
              "image": null,
              "quizzes": [
                  {
                      "id": 20,
                      "quiz": {
                          "id": 3,
                          "name": "Weinquiz",
                          "version": 1,
                          "createdAt": "2022-03-21T17:16:21.900Z",
                          "updatedAt": "2022-08-21T20:49:19.832Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 22,
                      "quiz": {
                          "id": 5,
                          "name": "Edeka Geschenke Quiz",
                          "version": 1,
                          "createdAt": "2022-08-04T19:36:16.389Z",
                          "updatedAt": "2022-08-04T19:36:16.389Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 23,
                      "quiz": {
                          "id": 6,
                          "name": "Edeka Essens Flow",
                          "version": 1,
                          "createdAt": "2022-08-04T20:09:47.286Z",
                          "updatedAt": "2022-08-04T20:09:47.286Z",
                          "language": "de"
                      }
                  }
              ],
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNjgwNjczNTE2LCJleHAiOjE2ODMyNjU1MTZ9.EpQr4OqmgKirQEdwrpWp1TZ3tuwXt6nnu8Rz8WOoRl8"
          },
          {
              "id": 22,
              "ign_id": null,
              "ign_code": "LVK",
              "name": "Ludwig von Kapff",
              "description": null,
              "createdAt": "2023-03-03T14:03:55.249Z",
              "updatedAt": "2023-03-24T14:21:18.115Z",
              "street": null,
              "zipcode": null,
              "city": null,
              "position": 0,
              "hidden": false,
              "enabled": true,
              "image": null,
              "quizzes": [
                  {
                      "id": 58,
                      "quiz": {
                          "id": 14,
                          "name": "Quiz LVK",
                          "version": 1,
                          "createdAt": "2023-03-21T10:24:10.306Z",
                          "updatedAt": "2023-03-23T06:11:05.714Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 57,
                      "quiz": {
                          "id": 13,
                          "name": "Geschenkeflow LVK",
                          "version": 1,
                          "createdAt": "2023-03-21T08:16:28.411Z",
                          "updatedAt": "2023-03-23T06:01:11.178Z",
                          "language": "de"
                      }
                  }
              ],
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNjgwNjczNTE2LCJleHAiOjE2ODMyNjU1MTZ9.EpQr4OqmgKirQEdwrpWp1TZ3tuwXt6nnu8Rz8WOoRl8"
          },
          {
              "id": 20,
              "ign_id": null,
              "ign_code": "FO-LE",
              "name": "Founders League",
              "description": "Quiz für Founders League",
              "createdAt": "2022-12-05T07:15:21.096Z",
              "updatedAt": "2022-12-07T15:30:56.651Z",
              "street": null,
              "zipcode": null,
              "city": null,
              "position": 0,
              "hidden": false,
              "enabled": true,
              "image": null,
              "quizzes": [
                  {
                      "id": 44,
                      "quiz": {
                          "id": 10,
                          "name": "Founders League Selection",
                          "version": 1,
                          "createdAt": "2022-12-06T06:02:54.218Z",
                          "updatedAt": "2022-12-06T06:08:31.547Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 45,
                      "quiz": {
                          "id": 5,
                          "name": "Edeka Geschenke Quiz",
                          "version": 1,
                          "createdAt": "2022-08-04T19:36:16.389Z",
                          "updatedAt": "2022-08-04T19:36:16.389Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 46,
                      "quiz": {
                          "id": 6,
                          "name": "Edeka Essens Flow",
                          "version": 1,
                          "createdAt": "2022-08-04T20:09:47.286Z",
                          "updatedAt": "2022-08-04T20:09:47.286Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 47,
                      "quiz": {
                          "id": 11,
                          "name": "Founders League Weinquiz",
                          "version": 1,
                          "createdAt": "2022-12-06T06:07:19.046Z",
                          "updatedAt": "2022-12-06T06:07:19.046Z",
                          "language": "de"
                      }
                  }
              ],
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNjgwNjczNTE2LCJleHAiOjE2ODMyNjU1MTZ9.EpQr4OqmgKirQEdwrpWp1TZ3tuwXt6nnu8Rz8WOoRl8"
          },
          {
              "id": 19,
              "ign_id": null,
              "ign_code": "12MIN",
              "name": "12 Minutes Me",
              "description": null,
              "createdAt": "2022-11-30T11:04:26.259Z",
              "updatedAt": "2023-02-26T20:02:56.350Z",
              "street": null,
              "zipcode": null,
              "city": null,
              "position": 0,
              "hidden": false,
              "enabled": true,
              "image": null,
              "quizzes": [
                  {
                      "id": 36,
                      "quiz": {
                          "id": 3,
                          "name": "Weinquiz",
                          "version": 1,
                          "createdAt": "2022-03-21T17:16:21.900Z",
                          "updatedAt": "2022-08-21T20:49:19.832Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 41,
                      "quiz": {
                          "id": 5,
                          "name": "Edeka Geschenke Quiz",
                          "version": 1,
                          "createdAt": "2022-08-04T19:36:16.389Z",
                          "updatedAt": "2022-08-04T19:36:16.389Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 42,
                      "quiz": {
                          "id": 6,
                          "name": "Edeka Essens Flow",
                          "version": 1,
                          "createdAt": "2022-08-04T20:09:47.286Z",
                          "updatedAt": "2022-08-04T20:09:47.286Z",
                          "language": "de"
                      }
                  }
              ],
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNjgwNjczNTE2LCJleHAiOjE2ODMyNjU1MTZ9.EpQr4OqmgKirQEdwrpWp1TZ3tuwXt6nnu8Rz8WOoRl8"
          },
          {
              "id": 18,
              "ign_id": null,
              "ign_code": "LO-KON",
              "name": "L'Osteria Konstanz",
              "description": null,
              "createdAt": "2022-11-01T08:41:54.869Z",
              "updatedAt": "2022-11-01T08:41:54.869Z",
              "street": null,
              "zipcode": null,
              "city": null,
              "position": 0,
              "hidden": false,
              "enabled": true,
              "image": null,
              "quizzes": [
                  {
                      "id": 35,
                      "quiz": {
                          "id": 7,
                          "name": "Weinquiz L'Osteria",
                          "version": 1,
                          "createdAt": "2022-09-18T18:35:47.538Z",
                          "updatedAt": "2022-09-18T19:25:21.422Z",
                          "language": "de"
                      }
                  }
              ],
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNjgwNjczNTE2LCJleHAiOjE2ODMyNjU1MTZ9.EpQr4OqmgKirQEdwrpWp1TZ3tuwXt6nnu8Rz8WOoRl8"
          },
          {
              "id": 16,
              "ign_id": null,
              "ign_code": "THE-ASH",
              "name": "The ASH",
              "description": null,
              "createdAt": "2022-10-26T22:15:28.399Z",
              "updatedAt": "2023-03-21T10:34:44.558Z",
              "street": null,
              "zipcode": null,
              "city": null,
              "position": 0,
              "hidden": false,
              "enabled": false,
              "image": null,
              "quizzes": [
                  {
                      "id": 33,
                      "quiz": {
                          "id": 9,
                          "name": "Quiz The ASH",
                          "version": 1,
                          "createdAt": "2022-12-02T21:28:26.366Z",
                          "updatedAt": "2022-12-02T21:28:26.366Z",
                          "language": "de"
                      }
                  }
              ],
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNjgwNjczNTE2LCJleHAiOjE2ODMyNjU1MTZ9.EpQr4OqmgKirQEdwrpWp1TZ3tuwXt6nnu8Rz8WOoRl8"
          },
          {
              "id": 15,
              "ign_id": null,
              "ign_code": "SPAR",
              "name": "Spar Weinwelt",
              "description": null,
              "createdAt": "2022-10-10T18:46:55.446Z",
              "updatedAt": "2022-11-19T12:20:43.490Z",
              "street": null,
              "zipcode": null,
              "city": null,
              "position": 0,
              "hidden": false,
              "enabled": true,
              "image": null,
              "quizzes": [
                  {
                      "id": 32,
                      "quiz": {
                          "id": 8,
                          "name": "Weinquiz Spar",
                          "version": 1,
                          "createdAt": "2022-10-28T07:47:57.699Z",
                          "updatedAt": "2022-11-09T11:40:09.445Z",
                          "language": "de"
                      }
                  }
              ],
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNjgwNjczNTE2LCJleHAiOjE2ODMyNjU1MTZ9.EpQr4OqmgKirQEdwrpWp1TZ3tuwXt6nnu8Rz8WOoRl8"
          },
          {
              "id": 14,
              "ign_id": null,
              "ign_code": "E-SEMPER",
              "name": "Edeka Tamme Semperhaus",
              "description": null,
              "createdAt": "2022-09-20T07:50:47.998Z",
              "updatedAt": "2022-10-05T16:58:33.834Z",
              "street": "Spitalerstraße 10-12",
              "zipcode": 20095,
              "city": "Hamburg",
              "position": 0,
              "hidden": false,
              "enabled": true,
              "image": null,
              "quizzes": [
                  {
                      "id": 25,
                      "quiz": {
                          "id": 3,
                          "name": "Weinquiz",
                          "version": 1,
                          "createdAt": "2022-03-21T17:16:21.900Z",
                          "updatedAt": "2022-08-21T20:49:19.832Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 27,
                      "quiz": {
                          "id": 5,
                          "name": "Edeka Geschenke Quiz",
                          "version": 1,
                          "createdAt": "2022-08-04T19:36:16.389Z",
                          "updatedAt": "2022-08-04T19:36:16.389Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 28,
                      "quiz": {
                          "id": 6,
                          "name": "Edeka Essens Flow",
                          "version": 1,
                          "createdAt": "2022-08-04T20:09:47.286Z",
                          "updatedAt": "2022-08-04T20:09:47.286Z",
                          "language": "de"
                      }
                  }
              ],
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNjgwNjczNTE2LCJleHAiOjE2ODMyNjU1MTZ9.EpQr4OqmgKirQEdwrpWp1TZ3tuwXt6nnu8Rz8WOoRl8"
          },
          {
              "id": 13,
              "ign_id": null,
              "ign_code": "E-AIRHAM",
              "name": "Edeka Tamme Flughafen",
              "description": null,
              "createdAt": "2022-09-20T07:49:18.833Z",
              "updatedAt": "2022-10-14T12:36:28.356Z",
              "street": null,
              "zipcode": null,
              "city": null,
              "position": 0,
              "hidden": false,
              "enabled": true,
              "image": null,
              "quizzes": [
                  {
                      "id": 24,
                      "quiz": {
                          "id": 3,
                          "name": "Weinquiz",
                          "version": 1,
                          "createdAt": "2022-03-21T17:16:21.900Z",
                          "updatedAt": "2022-08-21T20:49:19.832Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 30,
                      "quiz": {
                          "id": 5,
                          "name": "Edeka Geschenke Quiz",
                          "version": 1,
                          "createdAt": "2022-08-04T19:36:16.389Z",
                          "updatedAt": "2022-08-04T19:36:16.389Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 31,
                      "quiz": {
                          "id": 6,
                          "name": "Edeka Essens Flow",
                          "version": 1,
                          "createdAt": "2022-08-04T20:09:47.286Z",
                          "updatedAt": "2022-08-04T20:09:47.286Z",
                          "language": "de"
                      }
                  }
              ],
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNjgwNjczNTE2LCJleHAiOjE2ODMyNjU1MTZ9.EpQr4OqmgKirQEdwrpWp1TZ3tuwXt6nnu8Rz8WOoRl8"
          },
          {
              "id": 3,
              "ign_id": null,
              "ign_code": "TW-HOME",
              "name": "Tailorwine Homepage",
              "description": null,
              "createdAt": "2022-03-21T17:16:45.348Z",
              "updatedAt": "2023-01-24T15:20:10.977Z",
              "street": null,
              "zipcode": null,
              "city": null,
              "position": 0,
              "hidden": false,
              "enabled": true,
              "image": null,
              "quizzes": [
                  {
                      "id": 1,
                      "quiz": {
                          "id": 1,
                          "name": "Quiz Homepage",
                          "version": 1,
                          "createdAt": "2022-02-18T08:45:14.447Z",
                          "updatedAt": "2022-04-14T20:14:22.790Z",
                          "language": "de"
                      }
                  }
              ],
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNjgwNjczNTE2LCJleHAiOjE2ODMyNjU1MTZ9.EpQr4OqmgKirQEdwrpWp1TZ3tuwXt6nnu8Rz8WOoRl8"
          },
          {
              "id": 11,
              "ign_id": null,
              "ign_code": "E-ZURHEIDE",
              "name": "EDEKA Zurheide Düsseldorf",
              "description": "Bio Abteilung",
              "createdAt": "2022-08-30T18:48:05.007Z",
              "updatedAt": "2022-10-04T12:20:41.782Z",
              "street": "Berliner Allee 52",
              "zipcode": 40212,
              "city": "Düsseldorf",
              "position": 0,
              "hidden": false,
              "enabled": true,
              "image": null,
              "quizzes": [
                  {
                      "id": 17,
                      "quiz": {
                          "id": 5,
                          "name": "Edeka Geschenke Quiz",
                          "version": 1,
                          "createdAt": "2022-08-04T19:36:16.389Z",
                          "updatedAt": "2022-08-04T19:36:16.389Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 18,
                      "quiz": {
                          "id": 6,
                          "name": "Edeka Essens Flow",
                          "version": 1,
                          "createdAt": "2022-08-04T20:09:47.286Z",
                          "updatedAt": "2022-08-04T20:09:47.286Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 19,
                      "quiz": {
                          "id": 3,
                          "name": "Weinquiz",
                          "version": 1,
                          "createdAt": "2022-03-21T17:16:21.900Z",
                          "updatedAt": "2022-08-21T20:49:19.832Z",
                          "language": "de"
                      }
                  }
              ],
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNjgwNjczNTE2LCJleHAiOjE2ODMyNjU1MTZ9.EpQr4OqmgKirQEdwrpWp1TZ3tuwXt6nnu8Rz8WOoRl8"
          },
          {
              "id": 10,
              "ign_id": null,
              "ign_code": "LO-NRW",
              "name": "L'Osteria NRW",
              "description": null,
              "createdAt": "2022-08-21T20:50:01.794Z",
              "updatedAt": "2022-09-24T08:26:51.422Z",
              "street": null,
              "zipcode": null,
              "city": null,
              "position": 0,
              "hidden": false,
              "enabled": true,
              "image": null,
              "quizzes": [
                  {
                      "id": 15,
                      "quiz": {
                          "id": 7,
                          "name": "Weinquiz L'Osteria",
                          "version": 1,
                          "createdAt": "2022-09-18T18:35:47.538Z",
                          "updatedAt": "2022-09-18T19:25:21.422Z",
                          "language": "de"
                      }
                  }
              ],
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNjgwNjczNTE2LCJleHAiOjE2ODMyNjU1MTZ9.EpQr4OqmgKirQEdwrpWp1TZ3tuwXt6nnu8Rz8WOoRl8"
          },
          {
              "id": 9,
              "ign_id": null,
              "ign_code": "LO-HH",
              "name": "L'Osteria Hamburg",
              "description": null,
              "createdAt": "2022-08-21T20:49:01.910Z",
              "updatedAt": "2023-01-10T09:53:35.963Z",
              "street": null,
              "zipcode": null,
              "city": null,
              "position": 0,
              "hidden": false,
              "enabled": true,
              "image": null,
              "quizzes": [
                  {
                      "id": 14,
                      "quiz": {
                          "id": 7,
                          "name": "Weinquiz L'Osteria",
                          "version": 1,
                          "createdAt": "2022-09-18T18:35:47.538Z",
                          "updatedAt": "2022-09-18T19:25:21.422Z",
                          "language": "de"
                      }
                  }
              ],
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNjgwNjczNTE2LCJleHAiOjE2ODMyNjU1MTZ9.EpQr4OqmgKirQEdwrpWp1TZ3tuwXt6nnu8Rz8WOoRl8"
          },
          {
              "id": 8,
              "ign_id": null,
              "ign_code": "LO-MUC",
              "name": "L'Osteria München",
              "description": null,
              "createdAt": "2022-08-21T20:48:23.202Z",
              "updatedAt": "2022-09-24T08:26:40.122Z",
              "street": null,
              "zipcode": null,
              "city": null,
              "position": 0,
              "hidden": false,
              "enabled": true,
              "image": null,
              "quizzes": [
                  {
                      "id": 13,
                      "quiz": {
                          "id": 7,
                          "name": "Weinquiz L'Osteria",
                          "version": 1,
                          "createdAt": "2022-09-18T18:35:47.538Z",
                          "updatedAt": "2022-09-18T19:25:21.422Z",
                          "language": "de"
                      }
                  }
              ],
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNjgwNjczNTE2LCJleHAiOjE2ODMyNjU1MTZ9.EpQr4OqmgKirQEdwrpWp1TZ3tuwXt6nnu8Rz8WOoRl8"
          },
          {
              "id": 7,
              "ign_id": null,
              "ign_code": "E-SPEYER",
              "name": "E-Center Stiegler Speyer",
              "description": null,
              "createdAt": "2022-08-03T14:27:22.560Z",
              "updatedAt": "2022-10-14T12:36:16.454Z",
              "street": null,
              "zipcode": null,
              "city": null,
              "position": 0,
              "hidden": false,
              "enabled": true,
              "image": null,
              "quizzes": [
                  {
                      "id": 6,
                      "quiz": {
                          "id": 5,
                          "name": "Edeka Geschenke Quiz",
                          "version": 1,
                          "createdAt": "2022-08-04T19:36:16.389Z",
                          "updatedAt": "2022-08-04T19:36:16.389Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 7,
                      "quiz": {
                          "id": 6,
                          "name": "Edeka Essens Flow",
                          "version": 1,
                          "createdAt": "2022-08-04T20:09:47.286Z",
                          "updatedAt": "2022-08-04T20:09:47.286Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 8,
                      "quiz": {
                          "id": 3,
                          "name": "Weinquiz",
                          "version": 1,
                          "createdAt": "2022-03-21T17:16:21.900Z",
                          "updatedAt": "2022-08-21T20:49:19.832Z",
                          "language": "de"
                      }
                  }
              ],
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNjgwNjczNTE2LCJleHAiOjE2ODMyNjU1MTZ9.EpQr4OqmgKirQEdwrpWp1TZ3tuwXt6nnu8Rz8WOoRl8"
          },
          {
              "id": 6,
              "ign_id": null,
              "ign_code": "E-FRANKENTHAL",
              "name": "EDEKA Stiegler Frankenthal",
              "description": null,
              "createdAt": "2022-08-03T14:26:27.654Z",
              "updatedAt": "2022-10-04T12:20:09.022Z",
              "street": null,
              "zipcode": null,
              "city": null,
              "position": 0,
              "hidden": false,
              "enabled": true,
              "image": null,
              "quizzes": [
                  {
                      "id": 9,
                      "quiz": {
                          "id": 3,
                          "name": "Weinquiz",
                          "version": 1,
                          "createdAt": "2022-03-21T17:16:21.900Z",
                          "updatedAt": "2022-08-21T20:49:19.832Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 11,
                      "quiz": {
                          "id": 5,
                          "name": "Edeka Geschenke Quiz",
                          "version": 1,
                          "createdAt": "2022-08-04T19:36:16.389Z",
                          "updatedAt": "2022-08-04T19:36:16.389Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 12,
                      "quiz": {
                          "id": 6,
                          "name": "Edeka Essens Flow",
                          "version": 1,
                          "createdAt": "2022-08-04T20:09:47.286Z",
                          "updatedAt": "2022-08-04T20:09:47.286Z",
                          "language": "de"
                      }
                  }
              ],
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNjgwNjczNTE2LCJleHAiOjE2ODMyNjU1MTZ9.EpQr4OqmgKirQEdwrpWp1TZ3tuwXt6nnu8Rz8WOoRl8"
          },
          {
              "id": 5,
              "ign_id": "0",
              "ign_code": "TW-MESSE",
              "name": "TailorWine Messe",
              "description": "Messeflow",
              "createdAt": "2022-04-29T08:24:18.468Z",
              "updatedAt": "2023-03-17T06:23:46.435Z",
              "street": "",
              "zipcode": null,
              "city": "",
              "position": 0,
              "hidden": false,
              "enabled": true,
              "image": "",
              "quizzes": [
                  {
                      "id": 54,
                      "quiz": {
                          "id": 5,
                          "name": "Edeka Geschenke Quiz",
                          "version": 1,
                          "createdAt": "2022-08-04T19:36:16.389Z",
                          "updatedAt": "2022-08-04T19:36:16.389Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 55,
                      "quiz": {
                          "id": 6,
                          "name": "Edeka Essens Flow",
                          "version": 1,
                          "createdAt": "2022-08-04T20:09:47.286Z",
                          "updatedAt": "2022-08-04T20:09:47.286Z",
                          "language": "de"
                      }
                  },
                  {
                      "id": 56,
                      "quiz": {
                          "id": 3,
                          "name": "Weinquiz",
                          "version": 1,
                          "createdAt": "2022-03-21T17:16:21.900Z",
                          "updatedAt": "2022-08-21T20:49:19.832Z",
                          "language": "de"
                      }
                  }
              ],
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNjgwNjczNTE2LCJleHAiOjE2ODMyNjU1MTZ9.EpQr4OqmgKirQEdwrpWp1TZ3tuwXt6nnu8Rz8WOoRl8"
          },
          {
              "id": 4,
              "ign_id": "2241",
              "ign_code": "AUBAJ",
              "name": "Bremer Freimarkt",
              "description": "Fass 2",
              "createdAt": "2022-04-06T18:13:08.228Z",
              "updatedAt": "2022-11-20T18:12:26.745Z",
              "street": "Am Donneresch 9",
              "zipcode": 27751,
              "city": "Delmenhorst",
              "position": 0,
              "hidden": false,
              "enabled": true,
              "image": "https://images.igetnow.com/customers/1827/location_banners/1734-20220406153325.jpg",
              "quizzes": [
                  {
                      "id": 2,
                      "quiz": {
                          "id": 3,
                          "name": "Weinquiz",
                          "version": 1,
                          "createdAt": "2022-03-21T17:16:21.900Z",
                          "updatedAt": "2022-08-21T20:49:19.832Z",
                          "language": "de"
                      }
                  }
              ],
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNjgwNjczNTE2LCJleHAiOjE2ODMyNjU1MTZ9.EpQr4OqmgKirQEdwrpWp1TZ3tuwXt6nnu8Rz8WOoRl8"
          }
      ],
      "quizzes": []
  };

// export const mockQuizzes = {
//     "stats": [
//         {
//             "name": "Quiz Teilnehmer (- / 5 ⭐️)",
//             "stat": 24,
//             "previousStat": 0,
//             "change": "2400.00%",
//             "changeType": "increase"
//         },
//         {
//             "name": "Quiz Absolviert",
//             "stat": "0.00%",
//             "previousStat": 0,
//             "change": "0.00%",
//             "changeType": "decrease"
//         },
//         {
//             "name": "Ø Quiz Dauer",
//             "stat": "0s",
//             "previousStat": "0s",
//             "change": "0%",
//             "changeType": "increase"
//         },
//         {
//             "name": "Ø Weinpreis",
//             "stat": "0€",
//             "previousStat": "0€",
//             "change": "0.00%",
//             "changeType": "decrease"
//         }
//     ],
//     "freeTextInputs": [
//         {
//             "topicLabel": "Speisen",
//             "questionTextValGroups": [
//                 {
//                     "questionTextVal": "Zu welchem Gericht soll der Wein passen",
//                     "answerTextVals": [
//                         {
//                             "count": 2,
//                             "answerTextVal": "gemüse spaghetti "
//                         },
//                         {
//                             "count": 1,
//                             "answerTextVal": "spaghetti "
//                         },
//                         {
//                             "count": 1,
//                             "answerTextVal": "fruchtig süßer wein "
//                         }
//                     ]
//                 }
//             ]
//         }
//     ],
//     "completedQuizzes": [
//         {
//             "id": "dzoi7vtxy8lig7kf",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Divers",
//                 "16-29",
//                 "Geburtstag",
//                 "Weinanfänger",
//                 "Deutschland"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "2bqkrozp4qqacn24",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Mann",
//                 "70+",
//                 "Dankeschön",
//                 "Weinprofi"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "uz9i01pne6jklnkc",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Frau",
//                 "30-49",
//                 "Mitbringsel",
//                 "Weinprofi"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "7csa9aii0lilu7rh",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Frau",
//                 "30-49",
//                 "Mitbringsel",
//                 "Weinanfänger",
//                 "Deutschland"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "5vztttuucrjnff4a",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Mann",
//                 "16-29",
//                 "Geburtstag",
//                 "Weinprofi"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "snv29shxvkzfjw7y",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Für mehr als eine Person",
//                 "30-49",
//                 "Date",
//                 "Weinprofis"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "q935jdvcbxytlctn",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Mann",
//                 "70+",
//                 "Mitbringsel",
//                 "Weinprofi",
//                 "Spanien"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "n4r9ixppf46jymev",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Mann",
//                 "50-69",
//                 "Dankeschön",
//                 "Weinanfänger",
//                 "Italien",
//                 "Mann",
//                 "50-69",
//                 "Geburtstag",
//                 "Weinanfänger",
//                 "Frankreich",
//                 "Mann",
//                 "50-69",
//                 "Dankeschön",
//                 "Weinprofi",
//                 "Mann",
//                 "50-69",
//                 "Dankeschön",
//                 "Weinprofi",
//                 "Argentinien"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "gwu44pseun7krbsg",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Frau",
//                 "30-49",
//                 "Date",
//                 "Weinprofi"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "e847m6n43qoou5l3",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Frau",
//                 "30-49",
//                 "Date",
//                 "Weinprofi",
//                 "Italien"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "aufin5pv4deao0fr",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Mann",
//                 "50-69",
//                 "Dankeschön",
//                 "Weinprofi"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "b67b4su1f9sdvmdc",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Für mehr als eine Person",
//                 "30-49",
//                 "Geburtstag",
//                 "Weinprofis",
//                 "Italien"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "g57numhg2vc6jyyc",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Mann",
//                 "30-49",
//                 "Dankeschön",
//                 "Weinprofi"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "3vls5lkcwimssgmu",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Für mehr als eine Person",
//                 "30-49",
//                 "Dankeschön",
//                 "Weinanfänger",
//                 "Keine Angabe"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "epbueif32nyyud8m",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Mann",
//                 "30-49",
//                 "Mitbringsel",
//                 "Weinanfänger",
//                 "Keine Angabe"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "tahkyvvxzx88tsxn",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Mann",
//                 "30-49",
//                 "Date",
//                 "Weinprofi"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "vwhd3m0qyqj4a81n",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Für mehr als eine Person",
//                 "30-49",
//                 "Geburtstag",
//                 "Weinanfänger",
//                 "Spanien"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "18hj1rn4rx9f01uy",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Frau"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "eo33xlhvlmu0wwa8",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Frau",
//                 "16-29",
//                 "Geburtstag",
//                 "Weinanfänger",
//                 "Spanien"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "d3dk7wzi4uv79ycd",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Mann",
//                 "50-69",
//                 "Dankeschön",
//                 "Weinprofi",
//                 "Keine Angabe"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "bo2229wob0e3zcw3",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Mann",
//                 "30-49",
//                 "Date",
//                 "Weinprofi",
//                 "Mann",
//                 "30-49",
//                 "Dankeschön",
//                 "Weinprofi"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "986yeko6mrnob8kp",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Mann",
//                 "30-49",
//                 "Geburtstag",
//                 "Weinanfänger",
//                 "Mann",
//                 "30-49",
//                 "Date",
//                 "Weinprofi"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "qw3wxg2xkojc6e4t",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Für mehr als eine Person",
//                 "16-29",
//                 "Geburtstag",
//                 "Weinanfänger",
//                 "Keine Angabe"
//             ],
//             "wineRecommendations": []
//         },
//         {
//             "id": "cvfzxzay5uujo0vx",
//             "tasteType": "Unknown",
//             "answers": [
//                 "Frau",
//                 "16-29",
//                 "Date",
//                 "Weinanfänger",
//                 "USA"
//             ],
//             "wineRecommendations": []
//         }
//     ],
//     "topFoodPairingWines": [
//         {
//             "id": 10929,
//             "name": "2020 Mas Andes Gran Reserva - Trocken",
//             "price": 12.95,
//             "handle": "https://www.ludwig-von-kapff.de/mas-andes-gran-reserva-137678.html",
//             "favorite": true,
//             "region_match": false,
//             "picture_front": "https://stage.dashboard.tailortool.de/uploads/image_a421fa199c.png",
//             "count": 3,
//             "dishes": [
//                 {
//                     "name": "Steak",
//                     "count": 2
//                 },
//                 {
//                     "name": "brijesh",
//                     "count": 1
//                 }
//             ]
//         },
//         {
//             "id": 11180,
//             "name": "2021 Krämer »White Stuff« Chardonnay - Trocken",
//             "price": 11.95,
//             "handle": "https://www.ludwig-von-kapff.de/kraemer-white-stuff-chardonnay-104986.html",
//             "favorite": true,
//             "region_match": false,
//             "picture_front": "https://stage.dashboard.tailortool.de/uploads/image_52f20d73f0.png",
//             "count": 2,
//             "dishes": [
//                 {
//                     "name": "Spaghetti Carbonara",
//                     "count": 2
//                 }
//             ]
//         },
//         {
//             "id": 10552,
//             "name": "2020 Finca Las Moras PAZ Malbec - Trocken",
//             "price": 11.95,
//             "handle": "https://www.ludwig-von-kapff.de/paz-malbec-de-finca-las-moras-816033.html",
//             "favorite": false,
//             "region_match": false,
//             "picture_front": "https://stage.dashboard.tailortool.de/uploads/image_c5d9a22936.png",
//             "count": 1,
//             "dishes": [
//                 {
//                     "name": "schnitzel",
//                     "count": 1
//                 }
//             ]
//         },
//         {
//             "id": 10553,
//             "name": "2020 Finca Las Moras Los Intocables Black Malbec - Trocken",
//             "price": 11.95,
//             "handle": "https://www.ludwig-von-kapff.de/finca-las-moras-los-intocables-black-malbec-171007.html",
//             "favorite": false,
//             "region_match": false,
//             "picture_front": "https://stage.dashboard.tailortool.de/uploads/image_8e1556d989.png",
//             "count": 1,
//             "dishes": [
//                 {
//                     "name": "schnitzel",
//                     "count": 1
//                 }
//             ]
//         },
//         {
//             "id": 10554,
//             "name": "2021 Finca Las Moras Barrel Select Malbec - Trocken",
//             "price": 8.95,
//             "handle": "https://www.ludwig-von-kapff.de/finca-las-moras-barrel-select-816050.html",
//             "favorite": false,
//             "region_match": false,
//             "picture_front": "https://stage.dashboard.tailortool.de/uploads/b8f39c5dd73d6616887bebf6b0f4763c13612318a94fc8c1963fdce45c9583ed_62a17b611c.webp",
//             "count": 1,
//             "dishes": [
//                 {
//                     "name": "schnitzel",
//                     "count": 1
//                 }
//             ]
//         }
//     ]
// }


