import _ from "lodash";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const theme = createTheme();

theme.typography.h5 = {
  fontSize: "1rem",
  textAlign: "center",
  "@media (min-width:600px)": {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem",
  },
};

interface WinesByTasteTypePropTypes {
  selection: any;
}

const WinesByTasteType = ({ selection }: WinesByTasteTypePropTypes) => {
  //loop for selectio.wines
  const [wines, setWines] = useState(selection.wines);

  function calculateWines(slection: any) {
    // console.log("selection.winesselection.wines = ",selection.wines)
    if (!slection) return [];
    // var wines = [];
    var wines = selection.wines;
    var groupedWines: any = [];
    //group selection by wines
    // for (let i = 0; i < selection.wines.length; i++) {
    //   const wineArray = selection.wines[i];
    //   console.log("wineArray = ",wineArray)
    //   console.log("wineArray.length = ",wineArray.length)
    //   for (let j = 0; j < wineArray.length; j++) {
    //     wines.push(wineArray[j]);
    //   }
    // }
    groupedWines = _.groupBy(wines, "id");
    console.log("groupedWines = ",groupedWines)
    // groupedWines = _.map(groupedWines, (wine: any) => {
    //   wine[0].count = wine.length;
    //   return wine[0];
    // });

    // groupedWines = _.orderBy(groupedWines, "count", "desc");
    return groupedWines;
  }
  useEffect(() => {});

  if (!selection) return null;
  return (
    <>
      <Box
        sx={{
          height: 700,
        }}
      >
        <ThemeProvider theme={theme}>
          <Typography variant="h5">
            Weinauswahl für das Geschmacksprofil:
            <span className={"font-bold"}>{selection.name}</span>
          </Typography>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            {selection.wine_description.replaceAll("*", "")}
          </Typography>
        </ThemeProvider>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 670 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Wein</TableCell>
                <TableCell align="right">Wie häufig angezeigt</TableCell>
                <TableCell align="right">Preis</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wines.map((wine: any, index: any) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {wine.name}
                  </TableCell>
                  <TableCell align="right">{wine.count}</TableCell>
                  <TableCell align="right">{wine.price}€</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default WinesByTasteType;
