import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslate } from "@refinedev/core";

interface modalPropType {
  isOpen: boolean;
  handleModal: any;
  title: string;
  content: any;
}

export default function Modal({
  isOpen,
  handleModal,
  title,
  content,
}: modalPropType) {
  const [open, setOpen] = useState(isOpen);
  const t = useTranslate();
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex flex-grow self-stretch min-w-full w-full md:items-end sm:items-center justify-center md:min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-full sm:p-6 md:w-2/4">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500"></p>
                      {content}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-bordeaux text-base font-medium text-white hover:bg-bordeaux focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bordeaux sm:text-sm"
                    onClick={() => handleModal(false)}
                  >
                    {t("return")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export interface quizeDaysResponseType {
  day: number;
  count: number;
  date: Date;
}

export interface Filter {
  startDate: string;
  endDate: string;
  token: string;
  restaurants: Restaurant[];
  quizzes: Quizze[];
}

export interface Restaurant {
  id: number;
  ign_id: string | null;
  ign_code: string;
  name: string;
  description: string | null;
  createdAt: string;
  updatedAt: string;
  street: string | null;
  zipcode: string | null;
  city: string | null;
  position: number;
  hidden: boolean;
  enabled: boolean;
  image: string | null;
  quizzes: Quizze[];
  token: string;
}

export interface Quizze {
  id: number;
  quiz: {
    id: number;
    name: string;
    version: number;
    createdAt: string;
    updatedAt: string;
    language: string;
  };
}

export interface RevenueData {
  name: string;
  sales: number[];
}

export interface ChartData {
  data: RevenueData[];
  months: string[];
}

export interface salesDataPropType {
  filter: Filter;
}

export interface SalesDataResponse {
  name: string;
  month: string;
  sales: number;
}

export interface CompletedQuizze {
  id: string;
  tasteType: string;
  answers: string[];
  wineRecommendations: string[];
}

export interface TopFiveFoodResponce {
  name: string;
  count: string;
}

export interface FreeTextInputAnswer {
  count: number;
  answerTextVal: string;
}

export interface FreeTextInputQuestion {
  questionTextVal: string;
  answerTextVals: FreeTextInputAnswer[];
}

export interface FreeTextInputTopic {
  topicLabel: string;
  questionTextValGroups: FreeTextInputQuestion[];
}

export interface FreeTextInputData {
  freeTextInputs: FreeTextInputTopic[];
}

export interface GeneralPropTypes {
  filter: Filter;
  setFilter: any;
}

export interface Dish {
  name: string;
  count: number;
}

export interface FoodPairingWine {
  id: number;
  name: string;
  price: number;
  handle: string;
  favorite: boolean;
  region_match: boolean;
  picture_front: string;
  count: number;
  dishes: Dish[];
}

export interface TopFoodPairingWinesType {
  topFoodPairingWines: FoodPairingWine[];
}

export interface RevenueData {
  name: string;
  sales: number[];
}

export interface ChartData {
  data: RevenueData[];
  months: string[];
}
