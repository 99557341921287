import { useState, useEffect } from "react";
import moment from "moment";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { useTranslate } from "@refinedev/core";

interface DateRangePickerType {
  filter: any;
  setFilter: any;
}

const DateRangeSelection = ({ filter, setFilter }: DateRangePickerType) => {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [focusedInput, setFocusedInput] = useState(null);
  const t = useTranslate();
  const orientation = window.matchMedia("(max-width: 700px)").matches
    ? "vertical"
    : "horizontal";

  const setFocusedInputFn = (input: any) => {
    setFocusedInput(input);
  };

  useEffect(() => {
    updateFilter();
  }, [startDate, endDate]);

  function updateFilter() {
    filter.startDate = startDate;
    filter.endDate = endDate;
    setFilter(filter);
  }

  function changeDateRange(range: any) {
    setStartDate(range.startDate);
    setEndDate(range.endDate);
  }
  function renderDatePresets() {
    return (
      <div>
        <button
          className="m-1 relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
          type="button"
          onClick={() => {
            setStartDate(moment());
            setEndDate(moment());
          }}
        >
          {t("today")}
        </button>
        <button
          className="m-1 relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
          type="button"
          onClick={() => {
            setStartDate(moment().subtract(7, "days"));
            setEndDate(moment());
          }}
        >
          {t("last7days")}
        </button>
        <button
          className="m-1 relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
          type="button"
          onClick={() => {
            setStartDate(moment().subtract(14, "days"));
            setEndDate(moment());
          }}
        >
          {t("last14days")}
        </button>
        <button
          className="m-1 relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
          type="button"
          onClick={() => {
            setStartDate(moment().subtract(30, "days"));
            setEndDate(moment());
          }}
        >
          {t("last30days")}
        </button>
      </div>
    );
  }
  return (
    <>
      <div className="">
        <DateRangePicker
          renderCalendarInfo={renderDatePresets}
          startDate={startDate} // momentPropTypes.momentObj or null,
          startDateId={"startDateId"}
          endDateId={"endDateId"}
          endDate={endDate} // momentPropTypes.momentObj or null,
          onDatesChange={({ startDate, endDate }: any) => {
            setStartDate(startDate);
            setEndDate(endDate);
          }} // PropTypes.func.isRequired,
          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={setFocusedInputFn} // PropTypes.func.isRequired,
          enableOutsideDays={true}
          isOutsideRange={() => false}
          disabled={false}
          showDefaultInputIcon
          customArrowIcon={""}
          // orientation={"horizontal"}
          orientation={orientation}
          //onClose={updateFilter}
        />
      </div>
    </>
  );
};

export default DateRangeSelection;
