import React, { useState } from 'react';
import { Sider as DefaultSider } from '@refinedev/mui';
import {
  Box,
  Drawer,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Tooltip,
  Button,
  IconButton,
  List,
} from '@mui/material';
import {
  ListOutlined,
  Logout,
  ExpandLess,
  ExpandMore,
  ChevronLeft,
  ChevronRight,
  MenuRounded,
  Dashboard,
} from '@mui/icons-material';
import {
  CanAccess,
  ITreeMenu,
  useIsExistAuthentication,
  useLogout,
  useTranslate,
  useLink,
  useMenu,
  useRefineContext,
} from '@refinedev/core';
import { useNavigate } from "react-router-dom";

export const CustomSider: typeof DefaultSider = ({ render }) => {
  const navigation = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [opened, setOpened] = useState(false);

  const drawerWidth = () => {
    if (collapsed) return 64;
    return 250;
  };

  const t = useTranslate();
  const Link = useLink();
  const { hasDashboard } = useRefineContext();
  const translate = useTranslate();

  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const isExistAuthentication = useIsExistAuthentication();
  const { mutate: mutateLogout } = useLogout();

  const [open, setOpen] = useState<{ [k: string]: any }>({});

  React.useEffect(() => {
    setOpen((previousOpen) => {
      const previousOpenKeys: string[] = Object.keys(previousOpen);
      const uniqueKeys = new Set([
        ...previousOpenKeys,
        ...defaultOpenKeys,
      ]);
      const uniqueKeysRecord = Object.fromEntries(
        Array.from(uniqueKeys.values()).map((key) => [key, true])
      );
      return uniqueKeysRecord;
    });
  }, [defaultOpenKeys]);

  const handleClick = (key: string) => {
    setOpen({ ...open, [key]: !open[key] });
  };

  const handleUploadDataCLick = (e: any) =>{
    e.preventDefault();
    navigation("/upload-data");
  }

  const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
    return tree.map((item: ITreeMenu) => {
      const { name, children, meta, key, list } = item;
      const icon = meta?.icon;
      const label = meta?.label ?? name;
      const parent = meta?.parent;
      const route =
        typeof list === 'string'
          ? list
          : typeof list !== 'function'
            ? list?.path
            : key;
      const isOpen = open[route || ''] || false;

      const isSelected = route === selectedKey;
      const isNested = !(parent === undefined);

      if (children.length > 0) {
        return (
          <CanAccess
            key={route}
            resource={name.toLowerCase()}
            action="list"
            params={{
              resource: item,
            }}
          >
            <div key={route}>
              <Tooltip
                title={label ?? name}
                placement="right"
                disableHoverListener={!collapsed}
                arrow
              >
                <ListItemButton
                  onClick={() => {
                    if (collapsed) {
                      setCollapsed(false);
                      if (!isOpen) {
                        handleClick(route || '');
                      }
                    } else {
                      handleClick(route || '');
                    }
                  }}
                  sx={{
                    pl: isNested ? 4 : 2,
                    justifyContent: 'center',
                    '&.Mui-selected': {
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      justifyContent: 'center',
                      minWidth: 36,
                      color: 'primary.contrastText',
                    }}
                  >
                    {icon ?? <ListOutlined />}
                  </ListItemIcon>
                  <ListItemText
                    primary={label}
                    primaryTypographyProps={{
                      noWrap: true,
                      fontSize: '16px',
                      fontWeight: isSelected ? 'bold' : 'normal',
                    }}
                  />
                  {!collapsed &&
                    (isOpen ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
              </Tooltip>
              {!collapsed && (
                <Collapse
                  in={open[route || '']}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {renderTreeView(children, selectedKey)}
                  </List>
                </Collapse>
              )}
            </div>
          </CanAccess>
        );
      }

      return (
        <CanAccess
          key={route}
          resource={name.toLowerCase()}
          action="list"
          params={{ resource: item }}
        >
          <ListItemButton
            component={Link}
            to={route}
            selected={isSelected}
            onClick={() => {
              setOpened(false);
            }}
            sx={{
               background: '#621940',
              '&:hover': {
                background: '#812c59',
              },
              '&.Mui-selected': {
                backgroundColor: isSelected ? '#fff' : '#621940',
                '&:hover': {
                  backgroundColor: isSelected ? '#ccb0bf' : '#621940',
                },
              },
              justifyContent: 'center',
              margin: '10px auto',
              borderRadius: '6px',
              minHeight: '46px',
              width: '90%',
            }}
          >
            <ListItemIcon
              sx={{
                justifyContent: 'center',
                minWidth: 36,
                color: isSelected ? '#621940' : '#fff',
              }}
            >
              {icon ?? <ListOutlined />}
            </ListItemIcon>
            <ListItemText
              primary={label}
              primaryTypographyProps={{
                noWrap: true,
                fontSize: '16px',
                fontWeight: 'normal',
                color: isSelected ? '#621940' : '#fff',
                marginLeft: '10px',
              }}
            />
          </ListItemButton>
        </CanAccess>
      );
    });
  };

  const dashboard = hasDashboard ? (
    <CanAccess resource="dashboard" action="list">
      <Tooltip
        title={translate('dashboard.title', 'Dashboard')}
        placement="right"
        disableHoverListener={!collapsed}
        arrow
      >
        <ListItemButton
          component={Link}
          to="/"
          selected={selectedKey === '/'}
          onClick={() => {
            setOpened(false);
          }}
          sx={{
            pl: 2,
            py: 1,
            '&.Mui-selected': {
              '&:hover': {
                backgroundColor:
                  selectedKey === '/' ? '#621940' : 'transparent',
              },
              backgroundColor:
                selectedKey === '/' ? '#812c59' : 'transparent',
            },
            justifyContent: 'center',
            margin: '10px auto',
            borderRadius: '12px',
            minHeight: '56px',
            width: '90%',
          }}
        >
          <ListItemIcon
            sx={{
              justifyContent: 'center',
              minWidth: 36,
              color: selectedKey === '/' ? '#fff' : '#808191',
              marginRight: '12px',
            }}
          >
            <Dashboard />
          </ListItemIcon>
          <ListItemText
            primary={translate('dashboard.title', 'Dashboard')}
            primaryTypographyProps={{
              noWrap: true,
              fontSize: '16px',
              fontWeight: selectedKey === '/' ? 'bold' : 'normal',
              color: selectedKey === '/' ? '#fff' : '#808191',
            }}
          />
        </ListItemButton>
      </Tooltip>
    </CanAccess>
  ) : null;

  const logout = isExistAuthentication && (
    <Tooltip
      title={t('buttons.logout', 'Logout')}
      placement="right"
      disableHoverListener={!collapsed}
      arrow
    >
      <ListItemButton
        key="logout"
        onClick={() => mutateLogout()}
        sx={{
          justifyContent: 'center',
          margin: '10px auto',
          borderRadius: '6px',
          minHeight: '46px',
          width: '90%',
          background: '#621940',
          '&:hover': {
            background: '#812c59',
          },
        }}
      >
        <ListItemIcon
          sx={{
            justifyContent: 'center',
            minWidth: 36,
            color: '#ffffff',
          }}
        >
          <Logout />
        </ListItemIcon>
        <ListItemText
          primary={t('buttons.logout', 'Logout')}
          primaryTypographyProps={{
            noWrap: true,
            fontSize: '16px',
            marginLeft: '10px',
          }}
          sx={{ color: '#ffffff' }}
        />
      </ListItemButton>
    </Tooltip>
  );

  const uploadcsv = isExistAuthentication && (
    <Tooltip
      title={t('buttons.uploadData', 'Upload Data')}
      placement="right"
      disableHoverListener={!collapsed}
      arrow
    >
      <ListItemButton
        key="upload-data"
        onClick={(e: any) => handleUploadDataCLick(e)}
        sx={{
          justifyContent: 'center',
          margin: '10px auto',
          borderRadius: '6px',
          minHeight: '46px',
          width: '90%',
          background: '#621940',
          '&:hover': {
            background: '#812c59',
          },
        }}
      >
        <ListItemIcon
          sx={{
            justifyContent: 'center',
            minWidth: 36,
            color: '#ffffff',
          }}
        >
          <Logout />
        </ListItemIcon>
        <ListItemText
          primary={t('Upload Data', 'Upload Data')}
          primaryTypographyProps={{
            noWrap: true,
            fontSize: '16px',
            marginLeft: '10px',
          }}
          sx={{ color: '#ffffff' }}
        />
      </ListItemButton>
    </Tooltip>
  );

  const items = renderTreeView(menuItems, selectedKey);

  const renderSider = () => {
    if (render) {
      return render({
        dashboard,
        logout,
        items,
        collapsed
      });
    }
    return (
      <>
        {dashboard}
        {items}
        {logout}
        {/* {uploadcsv} */}
      </>
    );
  };

  const drawer = (
    <List disablePadding sx={{ mt: 1, color: '#808191' }}>
      {renderSider()}
    </List>
  );

  return (
    <>
      <Box
        sx={{
          width: { xs: drawerWidth() },
          display: {
            xs: 'none',
            md: 'block',
          },
          transition: 'width 0.3s ease',
        }}
      />
      <Box
        component="nav"
        sx={{
          position: 'fixed',
          zIndex: 1101,
          width: { sm: drawerWidth() },
          display: 'flex',
        }}
      >
        <Drawer  //this drawer is used for mobile
          variant="temporary"
          open={opened}
          onClose={() => setOpened(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              bgcolor: 'rgb(234 233 226  )',
            },
          }}
        >
          <Box
            sx={{
              height: 64,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src="images/logo_tailorwine.svg"
              alt="TailorWine"
              width="80px"
              height="80px"
            />
          </Box>
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          PaperProps={{ elevation: 0 }}
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              bgcolor: 'rgb(234 233 226  )',
              overflow: 'hidden',
              transition:
                'width 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            },
          }}
          open
        >
          <Box
            sx={{
              height: 84,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src="images/logo_tailorwine.svg"
              alt="TailorWine"
              width="80px"
              height="80px"
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              overflowX: 'hidden',
              overflowY: 'auto',
            }}
          >
            {drawer}
          </Box>
          {/*  This box is used for opening and closing drawer incon button at bottom   */}
          <Button
            sx={{
              background: '#621940',
              color: 'primary.contrastText',
              textAlign: 'center',
              borderRadius: 0,
              borderTop: '1px solid #ffffff1a',
              '&:hover': {
                background: '#812c59',
              },
            }}
            fullWidth
            size="large"
            onClick={() => setCollapsed((prev) => !prev)}
          >
            {collapsed ? <ChevronRight /> : <ChevronLeft />}
          </Button>
        </Drawer>
        {/* This box is needed to display menu items in mobile */}
        <Box
          sx={{
            display: { xs: 'block', md: 'none' },
            position: 'fixed',
            top: '64px',
            left: '0px',
            borderRadius: '0 6px 6px 0',
            bgcolor: '#621940',
            zIndex: 1199,
            width: '36px',
          }}
        >
          <IconButton
            sx={{ color: '#fff', width: '36px' }}
            onClick={() => setOpened((prev) => !prev)}
          >
            <MenuRounded />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};
