import React from "react";
import { useLogin } from "@refinedev/core";
import {
  Row,
  Col,
  Layout as AntdLayout,
  Typography,
  Form,
  Input,
  Button,
} from "antd";
import { LockOutlined } from "@mui/icons-material";
import "./styles.css";

export interface ILoginForm {
  email: string;
  password: string;
}

export const Login: React.FC = () => {
  const [form] = Form.useForm<ILoginForm>();

  const { mutate: login } = useLogin<ILoginForm>();

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <img
                className="img"
                src="images/logo_tailorwine.svg"
                alt="TailorWine Logo"
              />
            </div>
            <>
              <Typography className="title">{"Partner Login"}</Typography>
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  login(values);
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                }}
              >
                <Form.Item name={"email"} rules={[{ required: true }]}>
                  <Input size="large" placeholder="Email" />
                </Form.Item>
                <Form.Item name={"password"} rules={[{ required: true }]}>
                  <Input type="password" size="large" placeholder="Password" />
                </Form.Item>

                <Button
                  style={{
                    background: "#621940",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                  icon={<LockOutlined style={{ marginRight: "8px" }} />}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: "1",
                    }}
                  >
                    <span>Login</span>
                  </div>
                </Button>
              </Form>
            </>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
