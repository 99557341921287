import { useEffect, useState } from "react";
import CheckIcon from "@heroicons/react/outline/CheckIcon";
import "react-dropzone-uploader/dist/styles.css";
import axios from "axios";
import Dropzone from "react-dropzone-uploader";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslate } from "@refinedev/core";
import { getTokenFromLocalCookie } from "common/CommonService";
import { Filter } from "common/Modal";
import i18n from "i18n";

interface handleChangeStatusType {
  meta: any;
  file: any;
  // status:any;
}

const mockMonthsData = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];


export default function UploadEvaluation() {
  const token = getTokenFromLocalCookie();
  const filter = { token: token };
  const [localFilter, setLocatlFilter] = useState({ ...filter });
  const [done, setDone]: any = useState(false);
  const [loading, setLoading] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant]: any = useState(null);
  const [selectedMonths, setSelectedMonths]: any = useState(null);
  const t = useTranslate();

  //Fetch Restaurant Data.
  function fetchRestaurants() {
    const url: string = process.env.REACT_APP_GET_RESTURANT_DATA ?? "";
    const token = getTokenFromLocalCookie();
    try {
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          setRestaurants(res.data);
          setSelectedRestaurant(res.data[0].id);
        });
    } catch (error: any) {
      toast.error(error);
    }
  }

  useEffect(() => {
    fetchRestaurants();
    // fetchMonths();
  }, []);

  // called every time a file's `status` changes
  const handleChangeStatus = (
    { meta, file }: handleChangeStatusType,
    status: any
  ) => {
    if (status === "done") {
      toast.success(`${meta.name} uploaded!`);
      i18n.changeLanguage(String(localStorage.getItem("i18nextLng")))
    } else if (status === "aborted") {
      toast.error(`${meta.name}, upload failed...`);
      i18n.changeLanguage(String(localStorage.getItem("i18nextLng")))
    } else if (status === "removed") {
      toast.error(`${meta.name}, removed...`);
      i18n.changeLanguage(String(localStorage.getItem("i18nextLng")))
    }
  };

  const handleSubmit = (files: any, allFiles: any) => {
    setLoading(true);
    console.log(files.map((f: any) => f.meta));

    console.log('filter', localFilter);
    // const json = JSON.stringify(localFilter);
    // const blob = new Blob([json], {
    //   type: 'application/json',
    // });
    const data = new FormData();
    data.append('files', files);
    axios
      .post(
        process.env.REACT_APP_BASE_URL + '/api/partner/upload/edeka',
        { files, localFilter },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then(function () {
        console.log('SUCCESS!!');
        setDone(true);
        setLoading(false);
        allFiles.forEach((f: any) => f.remove());
      })
      .catch(function () {
        console.log('FAILURE!!');
      });
  };


  return (
    <div

      style={{ textAlign: "center", marginTop:'40px' }}
      className={
        "xs:m-10 mt-10 relative min-h-full bg-bordeaux flex flex-col items-center justify-center p-4 rounded-lg shadow-sm m-auto shadow-black"
      }
    >
      <ToastContainer />
      {!done && (
        <div
          style={{
            maxWidth: "350px",
            margin: "auto",
            backgroundColor: "rgb(98 25 64)",
            borderRadius: "10px",
            color: "#fff",
            textAlign: "center",
          }}
        >
          <div
            style={{
              padding: "20px",
            }}
          >
            <p className={"text-center text-white text-lg  mb-3 mt-7"}>
              1. {t("selectLocation", "Select location")}
            </p>
            <Select
              sx={{
                width: 300,
                height: 40,
                marginBottom: 1,
                color: "#000",
                backgroundColor: "#fff",
                textAlign: "start",
              }}
              value={selectedRestaurant}
              onChange={(e: any) => {
                setSelectedRestaurant(e.target.value);
              }}
              autoComplete="location"
              className=""
            >
              {restaurants.map((restaurant: any) => (
                <MenuItem key={restaurant.id} value={restaurant.id}>
                  {restaurant.name}
                </MenuItem>
              ))}
            </Select>
            <p className={"text-white text-center text-lg mb-3 mt-7"}>
              2. {t("selectMonth", "Select Month")}
            </p>
            <Select
              sx={{
                width: 300,
                height: 40,
                marginBottom: 1,
                color: "#000",
                backgroundColor: "#fff",
                textAlign: "start",
              }}
              value={!selectedMonths ? "Apr" : selectedMonths}
              onChange={(e: any) => {
                setSelectedMonths(e.target.value);
              }}
              autoComplete="location"
              className=""
            >
              {mockMonthsData.map((months: any) => (
                <MenuItem key={months} value={months}>
                  {months}
                </MenuItem>
              ))}
            </Select>
            {!done && !loading && (
              <>
                <p className={"text-white text-center text-lg mb-3 mt-7"}>
                  3. {t("uploadReport", "Upload report")}
                </p>
                <Dropzone
                  onChangeStatus={handleChangeStatus}
                  onSubmit={handleSubmit}
                  maxFiles={1}
                  multiple={false}
                  canCancel={false}
                  canRestart={true}
                  canRemove={true}
                  accept="*"
                  submitButtonContent="Bericht hochladen"
                  inputContent={t(
                    "dragAndDropClick",
                    "Drag & drop report or click here"
                  )}
                  styles={{
                    inputLabel: {
                      color: "white",
                      padding: "10px",
                      fontSize: "13px",
                      textAlign: "center",
                    },
                    submitButton: {
                      backgroundColor: "#621940",
                      color: "white",
                    },
                    preview: { color: "white", backgroundColor: "white" },
                    inputLabelWithFiles: { color: "white" },
                    input: { color: "white" },
                    dropzone: {
                      width: 300,
                      height: 200,
                      borderStyle: "dashed",
                    },
                    dropzoneActive: { borderColor: "green" },
                  }}
                ></Dropzone>
              </>
            )}
          </div>
        </div>
      )}
      {loading && (
        <div
          style={{
            textAlign: "center",
            width: "350px",
            height: "200px",
            padding: "10px",
            margin: "auto",
            border: "2px dashed #d9d9d9",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className={" "}>
            <div className={"flex items-center"}>
              <div>
                {" "}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 38 38"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#fff"
                >
                  <g fill="none" fillRule="evenodd">
                    <g transform="translate(1 1)" strokeWidth="2">
                      <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                      <path d="M36 18c0-9.94-8.06-18-18-18">
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          from="0 18 18"
                          to="360 18 18"
                          dur="1s"
                          repeatCount="indefinite"
                        />
                      </path>
                    </g>
                  </g>
                </svg>
              </div>
              <div className={"text-white ml-2"}>Bericht wird hochgeladen</div>
            </div>
          </div>
        </div>
      )}
      {done && (
        <div
          style={{
            textAlign: "center",
            width: "350px",
            height: "200px",
            padding: "10px",
            margin: "auto",
            border: "2px dashed #d9d9d9",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className={"inline-flex text-white"}>
            <CheckIcon
              style={{
                height: "30px",
              }}
            ></CheckIcon>
            <p>Bericht wurde erfolgreich hochgeladen</p>
            <button className="button button-primary"></button>
          </div>
        </div>
      )}
    </div>
  );
}
