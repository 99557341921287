import * as React from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SupportIcon from "@mui/icons-material/Support";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "@refinedev/core";

interface SupprtType {
  openDialog: boolean;
}

export default function Support({ openDialog }: SupprtType) {
  const navigation = useNavigate();
  const [open, setOpen] = React.useState(openDialog);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  const t = useTranslate();

  const handleClose = () => {
    navigation("/dashboard");
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SupportIcon />
            <p style={{ margin: "0" }}>{t("support", "Support")}</p>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: "center", color: "#000" }}>
            <p style={{ margin: "1px" }}>
              {t(
                "questionsAndSupport",
                "For questions or support, you can reach us at:"
              )}
              <br />
              <br />
              support@tailorwine.de <br />
              <br />
              {t("or", "Or")} <br />
              <br />
              +49 171 6077070
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: "rgb(98 25 64)",
              color: "#fff",
              textAlign: "center",
              width: "100%",
            }}
            onClick={handleClose}
          >
            {t('return')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
