import * as React from 'react';
import { useContext } from "react";
import DialogContent from "@mui/material/DialogContent";
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';
import Chip from "@mui/material/Chip";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { FreeTextInputAnswer,FreeTextInputTopic } from "common/Modal";
import { useTranslate } from "@refinedev/core";
import { DashboardContext } from '../dashboard-context';


export default function TopFiveFoods() {
  const { data } = useContext(DashboardContext) || { data: null };
  const { allData } = useContext(DashboardContext) || { allData: null };
  const t = useTranslate();
  
  if (allData != null){
  var testFiveFood: FreeTextInputTopic[] = allData.length > 0 && allData[0].top_five_foods.freeTextInputs;
 
  if (testFiveFood && testFiveFood.length > 0) {
     var topFiveFood: FreeTextInputAnswer[] =  testFiveFood[0].questionTextValGroups[0].answerTextVals
    return (
      <>
        <DialogContent>
          <Typography variant="h5" component="h5">
            <div className="flex items-center">
              <p style={{ margin: 0, fontSize: "20px", fontWeight: 600 }}>{t('top5Food')}</p>
              <ArrowUpwardIcon
                className="ml-1 mr-0.5 h-5 w-5 text-green-500"
                aria-hidden="true"
              />
            </div>
          </Typography>
          <List>
            {topFiveFood.slice(0, 5).map((meal, index) => (
              <ListItem disablePadding key={index}>
                <ListItemText>
                  <span>
                    <Chip
                      sx={{
                        bgcolor: "#DCFCE7",
                        color: "#47865D",
                        marginRight: 1,
                        fontSize: '14px',
                        fontWeight: '400'
                      }}
                      size="small"
                      label={meal.count}
                    />
                  </span>
                  <span style={{ fontSize: '16px', fontWeight: '600' }}>
                    {meal.answerTextVal}
                   </span>

                </ListItemText>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </>
    )
  } else {
    return null;
  }
}else{
  return null;
}
}
