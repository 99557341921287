import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "../../style/global.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslate } from "@refinedev/core";

interface CreateProductFormType {
  showForm: any;
  setShowForm: any;
  product: any;
  location: any;
  gtin: string;
  // reset: any;
  token: any;
  // searchProduct: any;
  // fetchAllWines: any;
  handleShowWines: any
}

export default function CreateProductForm({
  showForm,
  setShowForm,
  product,
  location,
  gtin,
  // reset,
  token,
  // searchProduct,
  // fetchAllWines,
  handleShowWines,
}: CreateProductFormType) {
  // const metadataObject = product?.metadata
  //   ? JSON.parse(product.metadata)
  //   : null;
  let metadataObject: any = {};

  try {
    metadataObject = product?.metadata ? JSON.parse(product.metadata) : {};
  } catch (error) {
    console.error("Error parsing metadata:", error);
    // Handle the error as needed, e.g., set metadataObject to a default value
  }
  const [gtinValue, setGtinValue] = useState(product?.drink?.gtin);
  const [nameValue, setNameValue] = useState(product?.name || "");
  const [priceValue, setPriceValue] = useState(product?.price || "");
  const [hideValue, setHideValue] = useState(product?.hidden || false);
  const [descriptionValue, setDescriptionValue] = useState(
    product?.drink?.description || ""
  );
  const [metaUrlValue, setMetaUrlValue] = useState(metadataObject?.url || "");
  const [metaOfferPriceValue, setMetaOfferPriceValue] = useState(
    metadataObject?.offer_price || ""
  );
  const [favoriteValue, setFavoriteValue] = useState(
    product?.favorite || false
  );
  const [isNewProduct, setIsNewProduct] = useState(!product);
  const t = useTranslate();

  //Api call on submit to create a new product
  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (metaUrlValue != "") {
      metadataObject["url"] = metaUrlValue;
    }

    if (metaOfferPriceValue != "") {
      metadataObject["offer_price"] = metaOfferPriceValue;
    }
    var metadataValue = metadataObject ? JSON.stringify(metadataObject) : null;
    console.log("====metadataValue = ", metadataValue);
    const url: string = process.env.REACT_APP_CREATE_PRODUCT ?? "";
    try {
      axios
        .post(
          url,
          {
            GTIN: gtinValue,
            name: nameValue,
            price: priceValue,
            location: location,
            isNewProduct: isNewProduct,
            hidden: hideValue,
            favorite: favoriteValue,
            description: descriptionValue,
            metadataValue: metadataValue,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            title: t("completed!", "Completed"),
            text: t("dataSavedSuccessfully", "The data was saved successfully"),
            icon: "success",
            confirmButtonText: t("okay", "Okay"),
            confirmButtonColor: "#621940",
          }).then((result) => {
            // reset();
          });
        });
    } catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <form onSubmit={handleSubmit} className="create-product-main-container">
        <div className="">
          <div className="">
            <div>
              <h3 className="">
                {t("productInformation", "Produkt Information")}
              </h3>
              <p className=""></p>
            </div>
            <div className="">
              <div className="">
                <label htmlFor="name" className="product-name-label">
                  {t("productName", "Produkt Name")}
                </label>
                <div className="">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={nameValue}
                    onChange={(e) => setNameValue(e.target.value)}
                    autoComplete="given-name"
                    className="product-name-input"
                  />
                </div>
              </div>

              <div className="">
                <label htmlFor="gtin" className="gtin-name-label">
                  GTIN
                </label>
                <div className="">
                  <input
                    type="text"
                    name="gtin"
                    id="gtin"
                    value={gtinValue}
                    onChange={(e) => setGtinValue(e.target.value)}
                    autoComplete="GTIN"
                    className="gtin-name-input"
                    disabled
                  />
                </div>
              </div>

              <div className="">
                <label htmlFor="price" className="preisin-name-label">
                  {t("priceIn€Preis", "Price in €")}
                </label>
                <div className="">
                  <input
                    id="price"
                    name="price"
                    type="number"
                    value={priceValue}
                    onChange={(e) => setPriceValue(e.target.value)}
                    min="1"
                    step="any"
                    autoComplete="price"
                    className="preisin-name-input"
                  />
                </div>
              </div>

              {!isNewProduct && (
                <div className="">
                  <label
                    htmlFor="offerprice"
                    className="product-offerprice-label"
                  >
                    {t("productMetaOfferPrice", "Product Offer Price")}
                  </label>
                  <div className="">
                    <input
                      type="number"
                      name="offerprice"
                      id="offerprice"
                      value={metaOfferPriceValue}
                      onChange={(e) => setMetaOfferPriceValue(e.target.value)}
                      autoComplete="given-offerprice"
                      className="product-offerprice-input"
                    />
                  </div>
                </div>
              )}

              {!isNewProduct && (
                <div className="">
                  <label
                    htmlFor="description"
                    className="product-description-label"
                  >
                    {t("productDescription", "Product Description")}
                  </label>
                  <div className="">
                    <textarea
                      // type="text"
                      name="description"
                      id="description"
                      value={descriptionValue}
                      onChange={(e) => setDescriptionValue(e.target.value)}
                      autoComplete="given-description"
                      className="product-description-input"
                    />
                  </div>
                </div>
              )}

              {!isNewProduct && (
                <div className="">
                  <label htmlFor="metaurl" className="product-metaurl-label">
                    {t("productMetaurl", "Product Url")}
                  </label>
                  <div className="">
                    <input
                      type="text"
                      name="metaurl"
                      id="metaurl"
                      value={metaUrlValue}
                      onChange={(e) => setMetaUrlValue(e.target.value)}
                      autoComplete="given-metaurl"
                      className="product-metaurl-input"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="">
            <div>
              <h3 className="">{t("otherOptions", "Other options")}</h3>
              <p className="create-product-sub-heading">
                {t(
                  "optionsIncludedText",
                  "The options you select here will be included in the algorithm."
                )}
              </p>
            </div>
            <div className="create-product-checked-main-container">
              <fieldset style={{ border: "none" }}>
                <legend className=""></legend>
                <div className="" aria-hidden="true"></div>
                <div className="">
                  <div className="create-product-checked-container">
                    <div className="">
                      <input
                        checked={hideValue}
                        onChange={(e) => setHideValue(e.target.checked)}
                        id="hide"
                        name="hide"
                        type="checkbox"
                        className="create-product-checked-box"
                      />
                    </div>
                    <div className="">
                      <label
                        htmlFor="hide"
                        className="create-product-main-checked-label"
                      >
                        {t("hide", "Hide")}
                      </label>
                      <p className="create-product-main-checked-label-paragraph">
                        {t(
                          "wineNoLongetSuggested",
                          "The wine is no longer suggested to customers."
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="create-product-checked-container">
                    <div className="">
                      <input
                        checked={favoriteValue}
                        onChange={(e) => setFavoriteValue(e.target.checked)}
                        id="fav"
                        name="fav"
                        type="checkbox"
                        className="create-product-checked-box"
                      />
                    </div>
                    <div className="">
                      <label
                        htmlFor="fav"
                        className="create-product-main-checked-label"
                      >
                        {t("thePrioritizer", "The prioritizer")}
                      </label>
                      <p className="create-product-main-checked-label-paragraph">
                        {t(
                          "wineSuggedtedText",
                          "The wine will be suggested to customers in preference."
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>

        <div className="">
          <div className="create-product-button-box">
            <button
              type="button"
              onClick={(e: any) => {
                setShowForm(false);
                // searchProduct();
                // fetchAllWines(location);
                handleShowWines(e);
              }}
              className="create-product-button-1st"
            >
              {t("return", "Return")}
            </button>
            <button type="submit" className="create-product-button-2nd">
              {t("saveOnComputer", "Save on computer")}
            </button>
          </div>
        </div>
      </form>
    </>
  );

  // return (
  //   <>
  //     <ToastContainer />
  //     <form onSubmit={handleSubmit} className="create-product-main-container">
  //       <div className="">
  //         <div className="">
  //           <div>
  //             <h3 className="">
  //               {t("productInformation", "Produkt Information")}
  //             </h3>
  //             <p className=""></p>
  //           </div>
  //           <div className="">
  //             <div className="">
  //               <label htmlFor="name" className="product-name-label">
  //                 {t("productName", "Produkt Name")}
  //               </label>
  //               <div className="">
  //                 <input
  //                   type="text"
  //                   name="name"
  //                   id="name"
  //                   value={nameValue}
  //                   onChange={(e) => setNameValue(e.target.value)}
  //                   autoComplete="given-name"
  //                   className="product-name-input"
  //                 />
  //               </div>
  //             </div>

  //             <div className="">
  //               <label htmlFor="gtin" className="gtin-name-label">
  //                 GTIN
  //               </label>
  //               <div className="">
  //                 <input
  //                   type="text"
  //                   name="gtin"
  //                   id="gtin"
  //                   value={gtinValue}
  //                   onChange={(e) => setGtinValue(e.target.value)}
  //                   autoComplete="GTIN"
  //                   className="gtin-name-input"
  //                 />
  //               </div>
  //             </div>

  //             <div className="">
  //               <label htmlFor="price" className="preisin-name-label">
  //                 {t("priceIn€Preis", "Price in €")}
  //               </label>
  //               <div className="">
  //                 <input
  //                   id="price"
  //                   name="price"
  //                   type="number"
  //                   value={priceValue}
  //                   onChange={(e) => setPriceValue(e.target.value)}
  //                   min="1"
  //                   step="any"
  //                   autoComplete="price"
  //                   className="preisin-name-input"
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //         </div>

  //         <div className="">
  //           <div>
  //             <h3 className="">{t("otherOptions", "Other options")}</h3>
  //             <p className="create-product-sub-heading">
  //               {t(
  //                 "optionsIncludedText",
  //                 "The options you select here will be included in the algorithm."
  //               )}
  //             </p>
  //           </div>
  //           <div className="create-product-checked-main-container">
  //             <fieldset style={{ border: "none" }}>
  //               <legend className=""></legend>
  //               <div className="" aria-hidden="true"></div>
  //               <div className="">
  //                 <div className="create-product-checked-container">
  //                   <div className="">
  //                     <input
  //                       checked={hideValue}
  //                       onChange={(e) => setHideValue(e.target.checked)}
  //                       id="hide"
  //                       name="hide"
  //                       type="checkbox"
  //                       className="create-product-checked-box"
  //                     />
  //                   </div>
  //                   <div className="">
  //                     <label
  //                       htmlFor="hide"
  //                       className="create-product-main-checked-label"
  //                     >
  //                       {t("hide", "Hide")}
  //                     </label>
  //                     <p className="create-product-main-checked-label-paragraph">
  //                       {t(
  //                         "wineNoLongetSuggested",
  //                         "The wine is no longer suggested to customers."
  //                       )}
  //                     </p>
  //                   </div>
  //                 </div>
  //                 <div className="create-product-checked-container">
  //                   <div className="">
  //                     <input
  //                       checked={favoriteValue}
  //                       onChange={(e) => setFavoriteValue(e.target.checked)}
  //                       id="fav"
  //                       name="fav"
  //                       type="checkbox"
  //                       className="create-product-checked-box"
  //                     />
  //                   </div>
  //                   <div className="">
  //                     <label
  //                       htmlFor="fav"
  //                       className="create-product-main-checked-label"
  //                     >
  //                       {t("thePrioritizer", "The prioritizer")}
  //                     </label>
  //                     <p className="create-product-main-checked-label-paragraph">
  //                       {t(
  //                         "wineSuggedtedText",
  //                         "The wine will be suggested to customers in preference."
  //                       )}
  //                     </p>
  //                   </div>
  //                 </div>
  //               </div>
  //             </fieldset>
  //           </div>
  //         </div>
  //       </div>

  //       <div className="">
  //         <div className="create-product-button-box">
  //           <button
  //             type="button"
  //             onClick={() => {
  //               setShowForm(false);
  //             }}
  //             className="create-product-button-1st"
  //           >
  //             {t("return", "Return")}
  //           </button>
  //           <button type="submit" className="create-product-button-2nd">
  //             {t("saveOnComputer", "Save on computer")}
  //           </button>
  //         </div>
  //       </div>
  //     </form>
  //   </>
  // );
}
