import React, { createContext, useEffect, useState, ReactNode } from "react";
import { Filter } from "common/Modal";

import {
  fetchParticipants,
  fetchEmails,
  fetchTasteTypes,
  fetchWines,
  fetchAllData,
} from "./dashboardService";

interface DashboardContextProps {
  data: any;
  allData: any;
  loading: boolean;
  emails: any;
  tasteTypes: any;
  wines: any;
}

interface DashboardProviderProps {
  children: ReactNode;
  filter: Filter;
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
}

export const DashboardContext = createContext<
  DashboardContextProps | undefined
>(undefined);

export const DashboardProvider: React.FC<DashboardProviderProps> = ({
  children,
  filter,
  setFilter,
}) => {
  const [data, setData] = useState<any>(null);
  const [allData, setAllData] = useState<any>(null);
  const [loading, setLoading]: any = useState(false);
  const [emails, setEmails] = useState([]);
  const [tasteTypes, setTasteTypes]: any = useState([]);
  const [wines, setWines]: any = useState([]);

  useEffect(() => {
    if (filter?.restaurants?.length) {
      setLoading(true);
      fetchParticipants(filter)
        .then((participants) => {
          setData(participants);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    if (filter?.restaurants?.length) {
      fetchEmails(filter).then((fetchedEmails) => {
        setEmails(fetchedEmails);
      });
    }
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    if (filter.restaurants?.length) {
      setLoading(true);
      fetchTasteTypes(filter)
        .then((tasteTypesData) => {
          setTasteTypes(tasteTypesData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    if (filter?.restaurants?.length) {
      fetchWines(filter).then((winesData) => {
        calcTopWines(winesData);
      });
    }
  }, [JSON.stringify(filter)]);

  useEffect(()=>{
    fetchAllData(filter).then((data: any) => {
      setAllData(data);
      console.log("-----------------data = ",data);
    });
  },[JSON.stringify(filter)])

  function calcTopWines(winesData: any) {
    winesData.sort((a: any, b: any) => {
      return b.count - a.count || b.count * b.price - a.count * a.price;
    });

    return setWines(winesData);
  }

  return (
    <DashboardContext.Provider
      value={{ data, loading, emails, tasteTypes, wines, allData }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
