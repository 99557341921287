import React, { useContext, useEffect, useState } from "react";
import * as echarts from "echarts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslate } from "@refinedev/core";
import { quizeDaysResponseType, Filter } from "../../common/Modal";
import { shiftDataArray } from "../../common/Common";
import { fetchAllData, fetchQuizDaysData } from "../dashboard/dashboardService";
import { DashboardContext } from "pages/dashboard/dashboard-context";

interface QuizDaysProps {
  filter: Filter;
}

const QuizDays: React.FC<QuizDaysProps> = ({ filter }) => {
  const [showChart, setShowChart] = useState(false);
  // var { allData } = useContext(DashboardContext) || { allData: null };
  const t = useTranslate();

  async function fetchData() {
    try {
      // const response: quizeDaysResponseType[] = await fetchQuizDaysData(filter);
      const allData = await fetchAllData(filter);
      const response: quizeDaysResponseType[] = allData.length > 0 ? allData[0].quiz_days : [];
      console.log("===response === ",response)
      if (response.length === 0) {
        setShowChart(false);
        return;
      }else{
        setShowChart(true);
      }

      const data = [0, 0, 0, 0, 0, 0, 0];
      const days = [
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
        "Sonntag",
      ];

      for (let i = 0; i < response.length; i++) {
        const day = response[i].day;
        const count = response[i].count;
        data[day] = count;
      }
      shiftDataArray(data);

      const chartData = {
        data: data,
        days: days,
        name: "xyz",
      };
      console.log("chartData= ",chartData)
      

      try {
        const chartDom: HTMLElement =
          document.getElementById("quiz-days-chart")!;
        const myChart = echarts.init(chartDom);
        var option;

        option = {
          legend: {
            data: chartData.data,
          },
          tooltip: {
            trigger: "axis",
          },
          color: ["#621940"],
          xAxis: {
            type: "category",
            left: "30%",
            data: chartData.days,
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: "{value}",
              align: "center",
            },
          },
          series: [
            {
              name: t("quizzes"),
              type: "bar",
              data: chartData.data,
            },
          ],
        };
        myChart.setOption(option);
      } catch (e) {
        console.log("Runtime exception occurred");
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(filter)]);

  return (
    <>
      <ToastContainer />
      {showChart && (
        <div className="margin-top-100">
          <p className="text-xl" style={{ textAlign: "center" }}>
            {t("number_of_completed_quizzes_by_day_of_the_week")}
          </p>
          <div
            className=""
            style={{ height: "500px", width: "100%" }}
            id="quiz-days-chart"
          ></div>
        </div>
      )}
    </>
  );
};

export default QuizDays;
