import {
  Button,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
} from "@mui/material";
import { Table } from "antd";
import axios from "axios";
import { getTokenFromLocalCookie } from "common/CommonService";
import { createRef, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { useTranslate } from "@refinedev/core";

export default function UploadCsv() {
  const token = getTokenFromLocalCookie();
  const filter = { token: token };
  const [localFilter, setLocatlFilter] = useState({ ...filter });
  const [selectedRestaurant, setSelectedRestaurant]: any = useState(null);
  const [selectedCategory, setSelectedCategory]: any = useState(null);
  const baseUrl: string = process.env.REACT_APP_BASE_URL ?? "";
  const [restaurants, setRestaurants] = useState([]);
  const [categories, setCategories] = useState([]);
  const fileInputRef = createRef<any>();
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [sheetData, setSheetData] = useState<any>([]);
  const [feedSheetData, setFeedSheetData] = useState<any>([]);
  const [sheetColumns, setSheetColumns] = useState<any>([]);
  const [feedSheetColumns, setFeedSheetColumns] = useState<any>([]);
  const [importType, setImportType] = useState<any>("csv");
  const [feedUrlValue, setFeedUrlValue] = useState<any>("");
  const [loading, setLoading] = useState(true);
  const t = useTranslate();
  const staticDropdownValues = [
    "Choose Column",
    "biological",
    "contains",
    "content_ml",
    "country",
    "description",
    "drinking_temperature",
    "fits_for",
    "grapes",
    "gtin",
    "import_image_url",
    "importer",
    "internal_id",
    "name",
    "natural_wine",
    "offer_price",
    "origin",
    "price",
    "producer",
    "product_link",
    "proof",
    "quality_level",
    "region",
    "residual_sugar",
    "sensoric_taste",
    "sourness_content",
    "vegan",
    "vintage",
    "weight_g",
    "winery",
    // "sweetness",
    // "sourness",
    // "tannin",
    // "alcohol",
    // "body",
    // "fruitiness",
    // "verified",
    // "tags",
    // "internal_notes",
    // "m_sweetness",
    // "m_sourness",
    // "m_tannin",
    // "m_alcohol",
    // "m_body",
    // "m_fruitiness",
    // "needs_rebsorte",
    // "image_verified",
    // "import_image_url",
    // "m_last_updated",
    // "m_data",
    // "m_orientation",
    // "unknown_grapes",
    // "picture_front",
    // "ign_id",
    // "created_by_id",
    // "updated_by_id",
    // "name",
    // "description",
    // "hidden",
    // "image",
    // "metadata",
    // "pitch_number",
    // "byglass",
    // "bybottle",
    // "favorite",
    // "data_hash",
    // "category",
  ];

  const handleImportTypeChange = (event: any) => {
    setImportType(event.target.value);
  };

  const parseCsv = (csvText: any) => {
    Papa.parse(csvText, {
      header: false,
      delimiter: ",",
      complete: (result: any) => {
        // Result.data contains an array of objects with the CSV data
        const headers = result.data[0];
        const rows = result.data.slice(1);
        console.log("result.data:", result.data);
        console.log("Headers:", headers);

        setFeedSheetColumns(
          headers?.map((header: any, key: any) => ({
            title: header,
            dataIndex: header,
          }))
        );
        console.log("Rows:", rows);
        const filteredRows = rows.filter(
          (row: any) => row.length > 1 || (row.length === 1 && row[0] !== "")
        );

        console.log("Rows (after):", filteredRows);

        const resultData = filteredRows.map((row: any) => {
          const obj: any = {};
          result.data[0].forEach((header: any, index: any) => {
            if (row[index]) {
              obj[header] = row[index] || "-";
            }
          });
          return obj;
        });
        setFeedSheetData(resultData);
      },
      error: (error: any) => {
        console.error("Error parsing CSV:", error.message);
      },
    });
  };

  const handleClickUploadFeedURL = async (e: any) => {
    e.preventDefault();
    console.log("feedUrlValue = ", feedUrlValue);
    try {
      const response = await fetch(feedUrlValue);
      const text = await response.text();
      parseCsv(text);
    } catch (error) {
      console.error("Error fetching CSV:", error);
    }
  };

  const handleClickSubmitFeedURL = async (e: any) => {
    e.preventDefault();
    console.log("feedSheetData = ", feedSheetData);

    const updatedDropdownRow = Object.fromEntries(
      feedSheetColumns.map((column: any) => [
        column.dataIndex,
        dropdownValues[column.dataIndex]
          ? dropdownValues[column.dataIndex]
          : "Choose Column",
      ])
    );

    // Generate the array of objects for upload
    const uploadData = [
      updatedDropdownRow,
      ...feedSheetData.map((row: any) => {
        const newRow = { ...row };
        // Ensure existing keys are present and not overwritten by dropdown values
        Object.keys(updatedDropdownRow).forEach((key) => {
          // if (!(key in newRow)) {
          newRow[key] = "";
          // }
        });
        return newRow;
      }),
    ];

    console.log("Upload Data[0] = ", uploadData[0]);
    console.log("selectedCategory = ", selectedCategory);

    // const obj = Object.assign({}, ...uploadData.slice(1).map((item: any, index: any) => ({ [index]: item })));
    // const obj = Object.assign(
    //   {},
    //   ...uploadData.map((item: any, index: any) => ({ [index]: item }))
    // );

    // console.log(obj);
    updateCategory(uploadData[0], selectedCategory);
  };

  const [dropdownValues, setDropdownValues] = useState<any>(
    Object.fromEntries(
      staticDropdownValues.map((column: any, index: any) => [
        column,
        "Choose Column",
      ])
    )
  );

  async function updateCategory(customFeedMapping: any, categoryId: any) {
    console.log("customFeedMapping = ", customFeedMapping);
    console.log("categoryId = ", categoryId);
    try {
      const expectedValues = [
        "name",
        "gtin",
        "proof",
        "grapes",
        "offer_price",
        "product_link",
        "import_image_url",
        "internal_id",
      ];
      const countMatches = Object.values(customFeedMapping).filter(
        (value: any) =>
          value !== "Choose Column" && expectedValues.includes(value)
      ).length;

      if (countMatches >= 8) {
        console.log("success");
      } else {
        toast.error("Please select a required columns.");
        console.log("Fail");
        return;
      }
      await axios
        .put(
          baseUrl +
            "/api/partner/data/restaurants/category?categoryId=" +
            categoryId,
          {customFeedMapping, feedUrlValue},
          {
            headers: {
              Authorization: "Bearer " + localFilter.token,
            },
          }
        )
        .then((res: any) => {
          console.log("res in category  = ", res);
          setFeedSheetData([]);
          setFeedUrlValue("");
          toast.success("Successfully updated data.");
        });
    } catch (error: any) {
      console.log("error in category  = ", error);
    }
  }

  function fetchRestaurants() {
    try {
      axios
        .get(baseUrl + "/api/partner/data/restaurants", {
          headers: {
            Authorization: "Bearer " + localFilter.token,
          },
        })
        .then((res) => {
          setRestaurants(res.data);
          setSelectedRestaurant(res.data[0].id);
          fetchCategories(res.data[0].id);
          setLoading(false);
        });
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
  }

  function fetchCategories(rsId: any) {
    try {
      axios
        .get(
          baseUrl +
            "/api/partner/data/restaurants/categories?restaurantId=" +
            rsId,
          {
            headers: {
              Authorization: "Bearer " + localFilter.token,
            },
          }
        )
        .then((res) => {
          //   setRestaurants(res.data);
          //   setSelectedRestaurant(res.data[0].id);
          console.log("Reswponse in categories = ", res);
          setCategories(res.data);
          setSelectedCategory(res.data[0].id);
        });
    } catch (error: any) {
      //   toast.error(error);
      console.log("eerrrorro in categoreis = ", error);
    }
  }

  useEffect(() => {
    setLoading(true);
    fetchRestaurants();
  }, []);

  const handleRestaurantChange = (selectedValue: any) => {
    const selectedRestaurant: any = restaurants.find(
      (restaurant: any) => restaurant.id === selectedValue
    );

    if (selectedRestaurant) {
      fetchCategories(selectedRestaurant?.id);
    }
  };

  const handleCategoryChange = (selectedValue: any) => {
    setSelectedCategory(selectedValue);
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      if (
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.name.endsWith(".xlsx") ||
        file.name.endsWith(".xls") ||
        file.name.endsWith(".csv")
      ) {
        setSelectedFile(() => file);

        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target?.result;

          const workbook = XLSX.read(data, { type: "binary" });
          const mainSheet = workbook.Sheets[workbook.SheetNames[0]];

          const parsedSheetData: any = XLSX.utils.sheet_to_json(mainSheet, {
            header: 1,
            blankrows: true,
          });

          if (parsedSheetData.length > 0) {
            const resultArray = parsedSheetData.slice(1).map((row: any) => {
              const obj: any = {};
              parsedSheetData[0].forEach((header: any, index: any) => {
                obj[header] = row[index] || "-";
              });
              return obj;
            });
            console.log("resultArray = ", resultArray);
            setSheetData(resultArray);

            setSheetColumns(
              parsedSheetData[0].map((header: any, key: any) => ({
                title: header,
                dataIndex: header,
              }))
            );
          }
        };
        reader.readAsBinaryString(file);
      } else {
        toast.error("Please select a valid Excel file.");
        resetFileInput();
      }
    }
  };

  const resetFileInput = () => {
    // Reset the file input to clear the selected file
    fileInputRef.current.value = null;
    setSelectedFile(null);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleUpload = async (e: any) => {
    e.preventDefault();
    console.log("sheetData = ", sheetData);

    const updatedDropdownRow = Object.fromEntries(
      sheetColumns.map((column: any) => [
        column.dataIndex,
        dropdownValues[column.dataIndex]
          ? dropdownValues[column.dataIndex]
          : "Choose Column",
      ])
    );

    // Generate the array of objects for upload
    // const uploadData = [
    //   updatedDropdownRow,
    //   ...sheetData.map((row: any) => {
    //     const newRow = { ...row };
    //     // Ensure existing keys are present and not overwritten by dropdown values
    //     Object.keys(updatedDropdownRow).forEach((key) => {
    //       if (!(key in newRow)) {
    //         newRow[key] = "";
    //       }
    //     });
    //     return newRow;
    //   }),
    // ];

    // console.log("Upload Data = ", uploadData[0]);
    console.log("updatedDropdownRow= ", updatedDropdownRow);
    const AuthStr = getTokenFromLocalCookie();
    try {
      const expectedValues = [
        "name",
        "gtin",
        "proof",
        "grapes",
        "offer_price",
        "product_link",
        "import_image_url",
        "internal_id",
      ];
      const countMatches = Object.values(updatedDropdownRow).filter(
        (value) => value !== "Choose Column" && expectedValues.includes(value)
      ).length;

      if (countMatches >= 7) {
        console.log("success");
      } else {
        toast.error("Please select a required columns.");
        console.log("Fail");
      }
      if (!selectedFile) {
        toast.error("Please select a file.");
        return;
      }
      const formData = new FormData();
      formData.append("files", selectedFile, selectedFile.name);
      // formData.append("columnList[]", uploadData[0]);
      Object.entries(updatedDropdownRow).forEach(
        ([key, value]: any, index: any) => {
          formData.append(key, value);
        }
      );

      const formDataObject: any = {};
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });
      console.log("formDataObjectv = ", formDataObject);
      await axios
        .post(
          baseUrl + "/api/partner/upload/csv?categoryId=" + selectedCategory,
          {
            formDataObject,
          },
          {
            headers: {
              Authorization: `Bearer ${AuthStr}`,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res: any) => {
          console.log("Response from api = ", res);
          setSelectedFile(null);
          if (res?.data?.status == "ok") {
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((error: any) => {
          console.log("error = ", error);
        });
    } catch (error: any) {
      console.log("error = ", error);
      toast.error(error);
    }
  };

  const handleDropdownChange = (event: any, index: any) => {
    const selectedValue = event.target.value;
    const newDropdownValues = { ...dropdownValues };
    newDropdownValues[index] = selectedValue;

    setDropdownValues(newDropdownValues);
  };

  return (
    <div>
      {loading ? (
        <div
          className="loaderStyle"
          style={{ marginLeft: "45%", marginBottom: "2%" }}
        >
          <Oval
            height={30}
            width={30}
            color="#621940"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#621940"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div
          // style={{ textAlign: "center", marginTop: "40px" }}
          className="upload_data_container"
        >
          <ToastContainer />
          <div className="slect_box_container">
            <div className="dropdown_flex">
              <div className="select_restaurant_container">
                <label htmlFor="select_restaurant">{t("selectRestaurant","Select Restaurant")}</label>
                <Select
                  value={selectedRestaurant}
                  onChange={(e: any) => {
                    setSelectedRestaurant(e.target.value);
                    handleRestaurantChange(e.target.value);
                  }}
                  id="select_restaurant"
                  autoComplete="location"
                  className="manageWine-dropDown"
                >
                  {restaurants.map((restaurant: any) => (
                    <MenuItem key={restaurant.id} value={restaurant.id}>
                      {restaurant.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="select_category_container">
                <label htmlFor="select_category">{t("selectCategory","Select Category")}</label>
                <Select
                  value={selectedCategory}
                  onChange={(e: any) => {
                    setSelectedCategory(e.target.value);
                    handleCategoryChange(e.target.value);
                  }}
                  id="select_category"
                  autoComplete="location"
                  className="manageWine-dropDown"
                >
                  {categories.map((category: any) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>

            <div className="select_import_type_container">
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={importType}
                onChange={handleImportTypeChange}
              >
                <FormControlLabel
                  value="csv"
                  control={<Radio />}
                  label={t("importExcelFile", "Import Excel File")}
                />
                <FormControlLabel
                  value="feed_url"
                  control={<Radio />}
                  label={t("feedUrl", "Feed Url")}
                />
              </RadioGroup>
            </div>
            {importType == "csv" && (
              <div
                className={`file_container ${
                  !selectedFile && "file_container_center"
                } `}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  accept=".csv, .xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
                <Tooltip title="Upload excel file">
                  <button onClick={handleButtonClick}>{t("upload", "Upload")}</button>
                </Tooltip>
                {selectedFile && (
                  <div className="filename-container">
                    <p className="selected_file_name">
                      {t("selectedFile","Selected File")}: {selectedFile.name}
                    </p>
                    <button
                      className="close-button"
                      onClick={() => resetFileInput()}
                    >
                      <i className="icofont icofont-close"></i>
                    </button>
                  </div>
                )}
                {selectedFile && (
                  <button onClick={(e: any) => handleUpload(e)}>{t("submit", "Submit")}</button>
                )}
              </div>
            )}
            {importType == "feed_url" && (
              <div className="feed_url_container">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={feedUrlValue}
                  onChange={(e) => setFeedUrlValue(e.target.value)}
                  autoComplete="given-name"
                  className="feed-url-input"
                />
                {feedUrlValue && (
                  <>
                    <button onClick={(e: any) => handleClickUploadFeedURL(e)}>
                      {t("upload", "Upload")}
                    </button>
                    <button onClick={(e: any) => handleClickSubmitFeedURL(e)}>
                      {t("submit", "Submit")}
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {sheetData.length > 0 && selectedFile && importType == "csv" && (
        <div className="sheet_data_table_csv">
          <Table
            dataSource={[
              {
                key: "dropdownRow",
                ...(Object.fromEntries(
                  sheetColumns.map((column: any) => [
                    column.dataIndex,
                    dropdownValues[column.dataIndex],
                  ])
                ) as Record<string, any>),
              },
              ...sheetData,
            ]}
            columns={sheetColumns.map((column: any) => ({
              ...column,
              render: (text: any, record: any) =>
                record.key === "dropdownRow" ? (
                  <Select
                    value={dropdownValues[column.dataIndex] || "Choose Column"}
                    onChange={(selectedValue) =>
                      handleDropdownChange(selectedValue, column.dataIndex)
                    }
                  >
                    {staticDropdownValues.map(
                      (option: any, optionIndex: any) => (
                        <MenuItem key={optionIndex} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                ) : (
                  text
                ),
            }))}
          />
        </div>
      )}

      {importType == "feed_url" && feedSheetData?.length > 0 && (
        <div className="sheet_data_table_csv">
          <Table
            dataSource={[
              {
                key: "dropdownRow",
                ...(Object.fromEntries(
                  feedSheetColumns.map((column: any) => [
                    column.dataIndex,
                    dropdownValues[column.dataIndex],
                  ])
                ) as Record<string, any>),
              },
              ...feedSheetData,
            ]}
            columns={feedSheetColumns.map((column: any) => ({
              ...column,
              render: (text: any, record: any) =>
                record.key === "dropdownRow" ? (
                  <Select
                    value={dropdownValues[column.dataIndex] || "Choose Column"}
                    onChange={(selectedValue) =>
                      handleDropdownChange(selectedValue, column.dataIndex)
                    }
                  >
                    {staticDropdownValues.map(
                      (option: any, optionIndex: any) => (
                        <MenuItem key={optionIndex} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                ) : (
                  text
                ),
            }))}
          />
        </div>
      )}
    </div>
  );
}
